<template>
    <div class="text-center">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="400"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
            <v-btn 
            icon 
            small 
            v-bind="attrs"
            v-on="on"
            color="#023E7D"
            class="me-2"
            @click="sortedNotifications"
            >
              <v-badge
                color="green"
                :content="noteCount"
                :value="noteCount"
                overlap
              >
                <v-icon>mdi-bell-outline</v-icon>
              </v-badge>
            </v-btn>
        </template>
  
        <v-card flat width="500">
          <div class="pa-4">
            <h4>Notifications</h4>
          </div>
            <v-responsive
            class="overflow-y-auto"
            max-height="200"
            >
                <v-responsive>
                  <v-expansion-panels
                    v-model="panel"
                    accordion
                    :multiple="allNotice"
                    flat
                  >
                    <v-expansion-panel
                    v-for="(item, i) in notifications"
                    :key="i"
                    >
                      <v-expansion-panel-header>
                        <v-list-item>
                        <v-list-item-avatar :color="item.is_read ? '#ced4da' : '#023E7D'">
                          <v-icon v-if="item.event_type === 'client_retained_attorney'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-handshake-outline' : 'mdi-handshake' }}</v-icon>
                          <v-icon v-if="item.event_type === 'attorney_match_request'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-hand-back-right-outline' : 'mdi-hand-back-right' }}</v-icon>
                          <v-icon v-if="item.event_type === 'client_match_request'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-hand-back-right-outline' : 'mdi-hand-back-right' }}</v-icon>
                          <v-icon v-else-if="item.event_type === 'create_chat'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-message-processing-outline' : 'mdi-message-processing'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'case_creation'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-briefcase-outline' : 'mdi-briefcase'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'case_progress'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-check-circle-outline' : 'mdi-check-circle'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'statute_of_limitation'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-calendar-today-outline' : 'mdi-calendar-today'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'client_dismissed_attorney'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-briefcase-remove-outline' : 'mdi-briefcase-remove'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'invoice_creation'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-invoice-text-outline' : 'mdi-invoice-text'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'client_booked_appointment'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-calendar-clock-outline' : 'mdi-calendar-clock'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'stripe_account_setup'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-alpha-s-circle-outline' : 'mdi-alpha-s-circle'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'case_status_update'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-briefcase-check-outline' : 'mdi-briefcase-check'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'boost_creation'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-lightning-bolt-outline' : 'mdi-lightning-bolt'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'review_rating'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-star-outline' : 'mdi-star'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'stripe_subscription'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-credit-card-outline' : 'mdi-credit-card'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'stripe_onboarding'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-credit-card-outline' : 'mdi-credit-card'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'invoice_payment'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-invoice-text-outline' : 'mdi-invoice-text'}}</v-icon>
                          <v-icon v-else-if="item.event_type === 'case_progress_update'" :color="item.is_read ? '#adb5bd' : '#FFFFFF'">{{item.is_read ? 'mdi-checkbox-multiple-marked-circle-outline' : 'mdi-checkbox-multiple-marked-circle'}}</v-icon>
                        </v-list-item-avatar>
            
                        <v-list-item-content>
                            <v-list-item-title
                            class="d-inline-block text-truncate"
                            style="max-width: 400px;"
                            >
                            <span :class="item.is_read ? 'is_read' : 'is_unread'">
                              <span v-if="item.event_type === 'client_retained_attorney'" ><span class="text-capitalize">{{ item.sender }}</span> retained you as an attorney</span>
                              <span v-if="item.event_type === 'attorney_match_request'">Match request from <span class="text-capitalize">{{ item.sender }}</span></span>
                              <span v-else-if="item.event_type === 'client_match_request'">Match request from <span class="text-capitalize">{{ item.sender }}</span></span>
                              <span v-else-if="item.event_type === 'create_chat'"><span class="text-capitalize">{{ item.sender }}</span> sent you a message</span>
                              <span v-else-if="item.event_type === 'case_creation'">New case ads added</span>
                              <span v-else-if="item.event_type === 'case_progress'">New case progress added</span>
                              <span v-else-if="item.event_type === 'statute_of_limitation'">New statute of limitation added</span>
                              <span v-else-if="item.event_type === 'client_dismissed_attorney'">Client dismmissed the case</span>
                              <span v-else-if="item.event_type === 'invoice_creation'">New invoice created</span>
                              <span v-else-if="item.event_type === 'client_booked_appointment'">New appointment booked</span>
                              <span v-else-if="item.event_type === 'stripe_account_setup'">New stripe account setup</span>
                              <span v-else-if="item.event_type === 'stripe_onboarding'">Stripe account setup completed</span>
                              <span v-else-if="item.event_type === 'case_status_update'">Case has been updated</span>
                              <span v-else-if="item.event_type === 'boost_creation'">New boost added</span>
                              <span v-else-if="item.event_type === 'review_rating'">New rating added</span>
                              <span v-else-if="item.event_type === 'invoice_payment'">New paid invoice</span>
                              <span v-else-if="item.event_type === 'stripe_subscription'">Subscription successful</span>
                              <span v-else-if="item.event_type === 'case_progress_update'">Case progress Updated</span>
                            </span>
                            <v-chip class="ms-2" v-show="item.is_public" small>
                              <span :class="item.is_read ? 'is_read' : 'is_unread'">General</span>
                            </v-chip>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span :class="item.is_read ? 'is_read' : 'is_unread'">{{ item.createdAt | formatDate }}</span> 
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        </v-list-item>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <span :class="item.is_read ? 'is_read' : 'is_unread'">{{ item.note }}</span>
                        <v-btn class="ms-2" v-show="item.event_type === 'case_progress'" small outlined>
                          <span :class="item.is_read ? 'is_read' : 'is_unread'">View</span>
                        </v-btn>
                      </v-expansion-panel-content>
                      <v-divider></v-divider>
                    </v-expansion-panel>
                  </v-expansion-panels>
                <v-list>
                    
                </v-list>
            </v-responsive>
          </v-responsive>

          <!-- <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn
              text
              small
              @click="menu = false"
            >
                Refresh - Auto
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-menu>
      <!-- snack bar -->
    <v-snackbar
    v-model="notifybar"
    absolute
    bottom
    color="#023E7D"
    text
    right
    >
    {{ message }}
    <template v-slot:action="{ attrs }">
        <v-btn
        color="white"
        icon
        v-bind="attrs"
        @click="notifybar = false; menu = true"
        >
        <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
    </template>
    </v-snackbar>

    <v-dialog
      v-model="refreshDialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-text class="py-4">Connection temporarily disconnected, click on the refresh button below to update.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="#023E7D"
            text
            @click="dialog = false"
          >
            Refresh Page
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </div>
</template>

<script>
  import eventBus from '../EventBus';

  export default {
    created: function () {
      this.updateNotification('getNotifications')

      this.$ws.addEventListener("open", () => {
        console.log("WebSocket connection established");
        // this.wsReady = true; // Set wsReady to true once WebSocket is open
      });

      eventBus.$on("caseCreationMessage", (data) => {
          this.updateAttorneyCase(data);
      });
      eventBus.$on("clientMatchMessage", (data) => {
          this.updateClientCase(data);
      });
      eventBus.$on("attorneyMatchMessage", (data) => {
          this.updateAttorneyCase(data);
      });
      eventBus.$on("attorneySetupMessage", (data) => {
          this.updateClientAttorneys(data);
      });
      eventBus.$on("caseProgressMessage", (data) => {
          this.updateClientCase(data);
      });
      eventBus.$on("⁠caseStatusUpdateMessage", (data) => {
          this.updateClientCase(data);
      });
      eventBus.$on("statuteLimitationMessage", (data) => {
          this.updateClientCase(data);
      });
      eventBus.$on("⁠progressUpdateMessage", (data) => {
          this.updateClientCase(data);
      });
      eventBus.$on("clientRetainedAttorneyMessage", (data) => {
          this.updateAttorneyCase(data);
      });
      eventBus.$on("clientDismissedAttorneyMessage", (data) => {
          this.updateAttorneyCase(data);
      });
      eventBus.$on("reviewRatingMessage", (data) => {
          this.updateAttorneyRatings(data);
      });
      eventBus.$on("stripeSetupMessage", (data) => {
          this.updateStripeSetup(data);
      });
      eventBus.$on("stripeOnboardingMessage", (data) => {
          this.updateStripeOnboard(data);
      });
      eventBus.$on("invoicePaymentMessage", (data) => {
          this.updateTransactions(data);
      });
      eventBus.$on("boostCreationMessage", (data) => {
          this.updateAds(data);
      });
      eventBus.$on("stripeSubscriptionMessage", (data) => {
          this.updateAttorneyProfile(data);
      });
      eventBus.$on("invoiceCreationMessage", (data) => {
          this.updateTransactions(data);
      });
      eventBus.$on("appointmentMessage", (data) => {
          this.updateAppointments(data);
      });
      // eventBus.$on("clientIdMessage", (data) => {
      //     this.updateClientId(data);
      // });
      eventBus.$on("chatMessage", (data) => {
          this.updateChat(data);
      });
      eventBus.$on("refreshDialog", (data) => {
        console.log(data)
          this.refreshDialog = true;
      });
    },

    mounted: function() {
      setTimeout(() => {
        this.noteCount = this.notificationCount
      }, 2000);
    },

    data: () => ({
      menu: false,
      notifybar: false,
      refreshDialog: false,
      message: '',
      allNotice: false,
      noteCount: 0,
      panel: []
    }),

    watch: {
      panel(v) {
        console.log(v)
        if(v.length != []) {
          let item = this.notifications[v]
          if(v != undefined && item.is_read == false) {
            this.markAsRead(item)
          }
          setTimeout(() => {
            this.noteCount = this.notificationCount
          }, 2000);
        }
      }
    },

    methods: {
      sortedNotifications() {
        this.panel = []
        return this.notifications.sort((a, b) => {
          return a.is_read - b.is_read;
        });
      },
      markAsRead(item) {
        this.$store.dispatch('updateReadNotification', {
          id: item.id,
          is_read: 1
        })
        .then((response) => {
        console.log(response)
        this.$store.commit('UPDATE_NOTIFICATION_READ_STATUS', response.data.data);
        // this.updateNotification('getNotifications')
        this.noteCount = this.notificationCount
        })
        .catch((error) => {
          this.notifybar = true;
          this.message = error.response.data.error
        })
      },
      updateNotification(v) {
        this.$store.dispatch('getNotification')
        .then((response) => {
        this.loading = false
        console.log(response)
        console.log('notification')
        if(v != 'getNotifications') {
          this.notifybar = true;
        }
        this.message = v
        })
        .catch((error) => {
          this.notifybar = true;
        this.message = error.response.data.error
        })
      },
      updateClientId() {
        console.log("updateClientAttorneys")
        this.updateNotification('Client id update')
      },
      updateClientAttorneys() {
        console.log("updateClientAttorneys")
        this.$store.dispatch('getClientCases')
        this.updateNotification('New Client Attorney update')
      },
      updateClientCase() {
        console.log("updateClientCase")
        this.$store.dispatch('getClientCases')
        this.updateNotification('New Client Case update')
      },
      updateAttorneyCase() {
        console.log("updateAttorneyCase")
        this.$store.dispatch('getAttorneyCases');
        this.updateNotification('New Attorney Case update')
      },
      updateAttorneyRatings() {
        console.log("updateAttorneyRatings")
        this.updateNotification('New Attorney Ratings update')
      },
      updateStripeSetup() {
        console.log("updateStripeSetup")
        this.updateNotification('New Stripe setup update')
      },
      updateStripeOnboard() {
        this.$store.dispatch('getUser');
        this.updateNotification('New Stripe setup completed')
      },
      updateAds() {
        console.log("updateAds")
        this.updateNotification('New Ads update')
      },
      updateAttorneyProfile() {
        console.log("updateAttorneyProfile")
        this.updateNotification('New Attorney Profile update')
      },
      updateTransactions() {
        console.log("updateTransactions")
        this.$store.dispatch('getAllInvoices')
        this.updateNotification('New Transaction update')
      },
      updateAppointments() {
        console.log("updateAppointments")
        this.$store.dispatch('getClientAppointments')
        this.updateNotification('New Appointments update')
      },
      updateChat(v) {
        console.log("updateChat")
        this.updateNotification(v.note)
      },
    },
    
    computed: {
      notifications() {
          return this.$store.state.notifications.data;
      },
      notificationCount() {
        let allNotifications = this.$store.state.notifications.data;
        return allNotifications.filter(notification => !notification.is_read).length;
      },
    }

  }
</script>

<style scoped>
.notify_date {
    font-size: 12px;
}
.is_read {
  color: grey;
}
.is_unread {
  color: black;
}
</style>../eventBus