<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12">
                    <h3 class="pageName">Settings</h3>
                    <v-row class="info_section">
                        <v-col lg="6">
                            <div class="d-flex justify-start">
                                <v-form
                                ref="form1"
                                style="position: relative; width: 80px"
                                >
                                <input type="file" ref="file" style="display: none" @change="onFileSelected" >
                                <v-avatar rounded size="80" color="#DCE8F8" avatar_box @click="$refs.file.click()">
                                    <v-img v-if="userDetails?.user?.image_url != 'img-cloud315r36bdhgevfg'" :src="userDetails?.user?.image_url" alt="" accept="image/*" aspect-ratio="1.1"></v-img>
                                    <v-img v-else-if="imgPlaceholder != ''" :src="imgPlaceholder" alt="" accept="image/*" aspect-ratio="1.1"></v-img>
                                    <v-icon v-else size="60" color="#85A4C7" dark>
                                        mdi-account
                                    </v-icon>
                                </v-avatar>
                                <v-progress-linear 
                                v-if="imgloader"
                                :value="12"
                                color="#f4a261"
                                indeterminate
                                absolute
                                center
                                bottom
                                ></v-progress-linear>
                                </v-form>
                                <div class="ms-4 pt-6">
                                    <div class="d-flex justify-start">
                                        <h3 class="main_topic text-capitalize">{{userDetails.user.last_name}} {{userDetails.user.first_name}}</h3>
                                        <!-- <v-btn
                                        icon
                                        small
                                        color="#023E7D"
                                        >
                                        <v-icon small>mdi-pencil-outline</v-icon>
                                        </v-btn> -->
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-icon 
                                        small
                                        class="ms-1"
                                        v-bind="attrs"
                                        v-on="on"
                                        :color="profileData.is_profile_verified ? '#f4a261' : 'grey'">
                                            mdi-check-decagram
                                        </v-icon>
                                        </template>
                                        <span>{{ profileData.is_profile_verified ? 'Verified' : 'Un-verified' }}</span>
                                    </v-tooltip>
                                    </div>
                                    <span>{{userDetails.user.email}}</span>
                                </div>
                            </div>
                        </v-col>
                        <v-col lg="6">
                            <div class="pa-4 profile_info d-flex justify-space-between">
                                <div>
                                    <h4 class="plan_name text-capitalize">{{planDetails.name}} Plan</h4>
                                    <div class="d-flex align-center">
                                        <v-icon small class="me-1">mdi-clock-time-two-outline</v-icon>
                                        <span v-if="profileData.is_trial" class="plan_text text-capitalize">Trial ends on {{profileData.trial_end_date | formatDate}}</span>
                                        <span v-else class="plan_text text-capitalize">Plan starts on {{planDetails.current_period_start | formatDate}}</span>
                                    </div>
                                </div>
                                <div>
                                    <h6 class="plan_text text-capitalize">Renewed on</h6>
                                    <v-chip
                                    color="#666666"
                                    outlined
                                    class="pe-1"
                                    >
                                    <h4 class="me-1 btn_text">{{planDetails.current_period_end | formatDate}}</h4>
                                    <v-btn
                                    icon
                                    small
                                    color="#666666"
                                    >
                                    <v-icon small>mdi-refresh</v-icon>
                                    </v-btn>
                                    </v-chip>
                                </div>
                            </div>
                        </v-col>
                        <v-col lg="6">
                            <div class="pa-4 profile_info">
                                <div class="d-flex justify-end">
                                    <v-chip
                                    color="#666666"
                                    outlined
                                    class="pe-1"
                                    >
                                    <h4 class="me-1 btn_text">Profile Information</h4>
                                    <v-btn
                                    icon
                                    small
                                    color="#666666"
                                    @click="profileInfo"
                                    >
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    </v-chip>
                                </div>
                                <v-row class="mt-2 details_card">
                                    <v-col cols="5">
                                        <h5>State</h5>
                                        <span>{{ userDetails.user.state }}</span>
                                    </v-col>
                                    <v-col cols="3">
                                        <h5>Postal code</h5>
                                        <span>{{ userDetails.user.zipcode }}</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <h5>Birthday</h5>
                                        <span>{{ userDetails.user.dob | formatDate }}</span>
                                    </v-col>
                                    <v-col cols="5">
                                        <h5>Phone</h5>
                                        <span>{{ userDetails.user.phone }}</span>
                                    </v-col>
                                    <v-col cols="7">
                                        <h5>Language</h5>
                                        <span
                                        v-for="(item, i) in userDetails.user.spoken_language"
                                        :key="i"
                                        >
                                            <span class="text-capitalize">{{ item }}, </span>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <h5>About</h5>
                                        <span>{{ userDetails?.profile?.contact_info?.about }}</span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col lg="6">
                            <div class="pa-4 profile_info">
                                <div class="d-flex justify-end">
                                    <v-chip
                                    color="#666666"
                                    outlined
                                    class="pe-1"
                                    >
                                    <h4 class="me-1 btn_text">Practice Information</h4>
                                    <v-btn
                                    icon
                                    small
                                    color="#666666"
                                    @click="practiceDetails"
                                    >
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    </v-chip>
                                </div>
                                <v-row class="mt-2 details_card">
                                    <v-col cols="12">
                                        <h5>Attorney Type</h5>
                                        <span
                                        v-for="(item, i) in userDetails.profile.attorney_type"
                                        :key="i"
                                        >
                                            <span>{{ item }}, </span>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <h5>Practice Area</h5>
                                        <span
                                        v-for="(item, i) in userDetails.profile.practice_area"
                                        :key="i"
                                        >
                                            <span>{{ item }}, </span>
                                        </span>
                                    </v-col>
                                    <v-col cols="12">
                                        <h5>Licensed Area</h5>
                                        <span
                                        v-for="(item, i) in userDetails.profile.licensed_areas"
                                        :key="i"
                                        >
                                            <span>{{ item.state }}, </span>
                                        </span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                        <v-col lg="12">
                            <div class="pa-4 profile_info">
                                <div class="d-flex justify-end">
                                    <v-chip
                                    color="#666666"
                                    outlined
                                    class="pe-1"
                                    >
                                    <h4 class="me-1 btn_text">Contact & Fees</h4>
                                    <v-btn
                                    icon
                                    small
                                    color="#666666"
                                    @click="contactFee"
                                    >
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                    </v-btn>
                                    </v-chip>
                                </div>
                                <v-row class="mt-2 details_card">
                                    <v-col lg="2.5">
                                        <h5>Show Ratings</h5>
                                        <v-switch
                                        v-model="showRatings"
                                        :loading="rateLoader"
                                        color="#023E7D"
                                        class="mt-0"
                                        hide-details
                                        disabled
                                        dense
                                        inset
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="1.5">
                                        <h5>Contigency</h5>
                                        <span>{{ profileDetails.payment_plan.contigency || '0' }}%</span>
                                    </v-col>
                                    <v-col cols="1.5">
                                        <h5>Hourly Rate</h5>
                                        <span>${{ profileDetails.payment_plan.hourly_rate || '0' }}</span>
                                    </v-col>
                                    <v-col cols="1.5">
                                        <h5>Fixed Rate</h5>
                                        <span>${{ profileDetails.payment_plan.fixed_rate || '0' }}</span>
                                    </v-col>
                                    <v-col cols="2">
                                        <h5>Payment Method</h5>
                                        <span
                                        v-for="(item, i) in profileDetails.payment_plan.payment_method"
                                        :key="i"
                                        >
                                            <span class="text-capitalize">{{ item }}, </span>
                                        </span>
                                    </v-col>
                                    <v-col cols="3">
                                        <h5>Website</h5>
                                        <span>{{ userDetails.profile.contact_info.website }}</span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="list_section">
                        <!-- <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="30" color="#DCE8F8" class="me-4">
                                        <v-icon size="18" color="#023E7D" dark>
                                          mdi-information-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">About Us</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col> -->
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="30" color="#DCE8F8" class="me-4">
                                        <v-icon size="18" color="#023E7D" dark>
                                          mdi-lightning-bolt-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="me-1 topic font-weight-medium">Boost Profile</span>
                                    <span v-if="boostDetails?.count > 0" class="topic font-weight-medium text-capitalize"> | ({{boostDetails?.data[0]?.type}} {{boostDetails?.data[0]?.start_date | formatDate}}) {{boostDetails?.data[0]?.status}}</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    @click="boostProfile = true"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="30" color="#DCE8F8" class="me-4">
                                        <v-icon size="18" color="#023E7D" dark>
                                          mdi-credit-card-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span
                                    v-if="profileDetails?.has_completed_stripe_account_setup"
                                    class="topic font-weight-medium"
                                    >
                                    {{ stripeDetails?.bank_name }}<span class="ms-1 text-uppercase">{{ stripeDetails?.brand }}</span> | <span>****{{ stripeDetails?.last4 }}</span>
                                    </span>
                                    <span 
                                    v-else
                                    class="topic font-weight-medium"
                                    >
                                    Stripe Setup
                                    </span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    v-if="profileDetails?.has_stripe_account"
                                    icon
                                    color="#023E7D"
                                    @click="secondaryCheck = true"
                                    >
                                    <v-icon>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                    <v-btn
                                    v-else
                                    icon
                                    color="#023E7D"
                                    @click="setupStripe = true"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                        <!-- <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="34" color="#DCE8F8" class="me-4">
                                        <v-icon color="#023E7D" dark>
                                          mdi-credit-card-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">Payment Information</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    @click="PaymentInfo = true"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col> -->
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="30" color="#DCE8F8" class="me-4">
                                        <v-icon size="18" color="#023E7D" dark>
                                          mdi-message-alert-outline
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">Send Feedback</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                        <v-col lg="6">
                            <div class="attorney_card d-flex justify-space-between">
                                <div class="attorney_card-box d-flex align-center">
                                    <v-avatar size="30" color="#DCE8F8" class="me-4">
                                        <v-icon size="18" color="#023E7D" dark>
                                          mdi-logout-variant
                                        </v-icon>
                                    </v-avatar>
                                    <span class="topic font-weight-medium">Logout</span>
                                </div>
                                <div class="attorney_card-box d-flex align-center">
                                    <v-btn
                                    icon
                                    color="#023E7D"
                                    to="/logout"
                                    >
                                    <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    
                </v-col>
                
            </v-row>
        </v-container>

        <!-- Stripe Setup -->
        <v-dialog
        v-model="setupStripe"
        max-width="500"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Stripe Setup
                </v-card-title>
            </div>
            <v-card-text 
            class="mt-8"
            >
                <v-row>
                    <v-col cols="12">
                        <p>What's your busniess website?</p>
                        <v-text-field
                        v-model="website"
                        label="Website"
                        filled
                        dense
                        hide-details="auto"
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-1">
                        <span>What's your Address and postal code?</span>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <v-text-field
                        v-model="address"
                        label="Address"
                        filled
                        dense
                        hide-details="auto"
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="4" class="d-flex align-end">
                        <v-text-field
                        v-model="postalCode"
                        label="Postal Code"
                        filled
                        dense
                        hide-details="auto"
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-text-field
                        v-model="state"
                        label="State"
                        filled
                        dense
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn 
                        block 
                        color="#023E7D" 
                        depressed 
                        dark
                        :loading="stripeLoader"
                        @click="createStripe"
                        >
                            Save
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- Boost Profile -->
        <v-dialog
        v-model="boostProfile"
        max-width="500"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                <v-btn
                v-show="boostToggle"
                icon
                small
                color="white"
                class="me-2"
                @click="boostToggle = false"
                >
                <v-icon small>mdi-arrow-left</v-icon>
                </v-btn>
                Boost Profile
                <v-spacer></v-spacer>
                <v-chip
                v-show="!boostToggle"
                color="white"
                outlined
                @click="boostToggle = true"
                >
                <h4 class="btn_text-light">New Boost</h4>
                </v-chip>
                </v-card-title>
            </div>
            <v-card-text 
            class="mt-4"
            >
                <v-row
                v-show="!boostToggle"
                >
                <v-col cols="12">
                    <div 
                    v-if="boostDetails?.count === 0"
                    class="text-center d-flex justify-center"
                    >
                        <v-card flat width="400">
                            <v-icon
                            size="100"
                            color="#D4D4D4"
                            >
                            mdi-lightning-bolt-outline
                            </v-icon>
                            <p class="placeholder_text">Nothing to see here at the moment, you don't have any boost added yet click new boost to do so.</p>
                        </v-card>
                    </div>
                    <v-responsive
                    v-else
                    class="mt-4 overflow-y-auto"
                    max-height="400"
                    >
                        <v-responsive>
                    <v-card 
                    v-for="(item, i) in boostDetails?.data"
                    :key="i"
                    flat
                    color="#F5F5F5"
                    class="pa-2 mb-2"
                    >
                    <v-list-item two-line>
                        <v-list-item-avatar
                            size="20"
                            color="grey"
                        >
                            <v-icon small dark>
                                mdi-lightning-bolt
                            </v-icon>
                        </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="mb-1 d-flex justify-space-between">
                            <span class="text-capitalize boost_title">{{item?.type}}</span>
                            <span class="text-capitalize boost_date">{{item?.start_date | formatDate}}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="d-flex justify-space-between">
                            <v-chip
                            color="#666666"
                            text-color="#666666"
                            outlined
                            small
                            >
                            <h4 class="text-capitalize">{{item?.status}}</h4>
                            </v-chip>
                            <div class="text-right">
                                <div class="mb-1 text-capitalize">{{item?.duration}} | starts on</div>
                                <div class="text-capitalize">{{item?.start_time | resetTime}} - {{item?.end_time | resetTime}}</div>
                            </div>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    </v-list-item>
                    </v-card>
                    </v-responsive>
                </v-responsive>
                </v-col>
                </v-row>
                <v-form
                ref="boostForm"
                v-model="boostValid"
                >                                       
                <v-row
                v-show="boostToggle"
                >
                    <v-col cols="12">
                        <p>What's the boost type?</p>
                        <v-select
                        v-model="boostType"
                        label="Boost Types"
                        :items="boostTypes"
                        :rules="[rules.objRequired]"
                        filled
                        dense
                        return-object
                        item-text="value"
                        hide-details="auto"
                        class="some-style"
                        @change="boostState"
                        ></v-select>
                    </v-col>
                    <v-col cols="6">
                        <p><v-icon class="me-1" size="15">mdi-information-variant-circle-outline</v-icon>What's the start date?</p>
                        <v-menu
                            ref="boostMenu"
                            v-model="boostMenu"
                            :close-on-content-click="false"
                            :return-value.sync="boostDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="boostDate"
                                label="Start Date"
                                hide-details="auto"
                                :rules="[rules.required]"
                                readonly
                                filled
                                dense
                                v-bind="attrs"
                                v-on="on"
                                class="some-style"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="boostDate"
                            no-title
                            scrollable
                            :min="new Date().toISOString().substr(0, 10)"
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="boostMenu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.boostMenu.save(boostDate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="6">
                        <p><v-icon class="me-1" size="15">mdi-information-variant-circle-outline</v-icon>What's the start time?</p>
                        <v-text-field
                        v-model="boostFrom"
                        label="Start Time"
                        :hide-details="true"
                        :rules="[rules.required, rules.timeValidation]"
                        filled
                        dense
                        @click="openTimePicker('boostFrom')"
                        readonly
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col lg="8">
                        <span class="boost_caption"><v-icon class="me-1" size="15">mdi-information-variant-circle-outline</v-icon>Pick number of {{boostType.value === 'hourly' ? 'hours' : 'days'}}</span>
                        <v-slider
                        v-model="boostSlider"
                        :max="boostMax"
                        :min="boostMin"
                        ticks="always"
                        tick-size="4"
                        thumb-label
                        hide-details
                        @click="boostSliderState"
                        ></v-slider>
                        
                    </v-col>
                    <v-col lg="4">
                        <h3 class="boost_cost"><span class="dollar_sign">$</span>{{boostCost}}</h3>
                        <span class="boost_caption">Cost - ${{boostType.cost}}/{{boostType.value}}</span>
                    </v-col>
                    <v-col cols="12">
                        <p>Enter card information</p>
                        <stripe-element-card
                            ref="elementRef"
                            :pk="publishableKey"
                            hidePostalCode
                            @token="tokenCreated"
                        />
                        <v-btn
                            color="#FFD700"
                            light
                            large
                            block
                            elevation="0"
                            class="mt-5"
                            :loading="loading"
                            @click="submit"
                        >
                        <b>Boost</b>
                        </v-btn>
                    </v-col>
                </v-row>
                </v-form>
                <div class="mt-4 text-center">
                    <span class="text_caption">Powered by <b>Stripe</b></span>
                </div>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- secondary check -->
        <v-dialog
        v-model="secondaryCheck"
        persistent
        max-width="310"
        >
        <v-card>
            <v-card-title>
            Do you want to delete your stripe account setup?
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                light
                class="me-2 optionCloseBtn"
                elevation="0"
                @click="cancelDialog = false"
            >
                No, i don’t
            </v-btn>
            <v-btn
                color="#053669"
                dark
                elevation="0"
                :loading="deleteStripeAcct"
                @click="deleteSetup"
            >
                Yes, i do
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>


        <!-- Payment Information -->
        <v-dialog
        v-model="PaymentInfo"
        max-width="700"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Payment Information
                </v-card-title>
            </div>
            <v-card-text class="mt-8">
                <v-row>
                    <v-col cols="12" lg="12">
                        <span>Account Details</span>
                        <v-list class="mt-3">
                            <v-list-item class="px-0">
                                <v-list-item-content class="py-0">
                                    <div class="attorney_card d-flex justify-space-between align-center">
                                        <div class="attorney_card-box">
                                            <h4 class="attorney_list-title">0048858084</h4>
                                            <span class="attorney_list-subtitle">Ameris Bank</span>
                                        </div>
                                        <v-menu bottom left>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            color="#023E7D"
                                            icon
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon>mdi-dots-vertical</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item>
                                            <v-list-item-title>Delete</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                        </v-menu>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" lg="12">
                        <span>Add a new account details</span>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-select
                        v-model="bank"
                        :items="banks"
                        filled
                        label="Select Bank"
                        class="some-style"
                        ></v-select>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-text-field
                        label="Account Number"
                        filled
                        dense
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                        <v-text-field
                        v-model="cosName"
                        label="Costumer's Name"
                        filled
                        dense
                        readonly
                        class="some-style"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn class="me-2" color="#023E7D" depressed outlined dark>
                            Cancel
                        </v-btn>
                        <v-btn color="#023E7D" depressed dark>
                            send invoice
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- Stripe Account Completion -->
        <v-dialog
        v-model="completeSetup"
        max-width="450"
        >
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text class="mt-8">
                <v-row>
                    <v-col lg="12">
                        <p>Thank you for initializing your account! To complete your Stripe Express account setup, please click on the link below</p>
                        <p>
                            <a :href="stripeUrl" target="_blank" at @click="completeSetup = false, setupStripe = false">Complete stripe setup.</a>
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- edit profile info -->
        <v-dialog
        v-model="profileInfoDialog"
        max-width="700"
        >
            <v-card>
                <v-card-title class="card_title">
                Update Profile
                </v-card-title>
                <v-card-text class="mt-8">
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="firstName"
                            label="First Name"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="lastName"
                            label="Last Name"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="phone"
                            label="Phone Number"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="date"
                                    label="MMDDYYYY"
                                    append-icon="mdi-calendar-blank-outline"
                                    hide-details="auto"
                                    hint="Most be 18 and above"
                                    persistent-hint
                                    filled
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="some-style"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="date"
                                no-title
                                scrollable
                                :max="maxDate"
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-select
                            v-model="selectedLanguages"
                            :items="languages"
                            label="Select Language"
                            hide-details="auto"
                            filled
                            multiple
                            class="some-style"
                            >
                            <template v-slot:selection="{ index }">
                                <span
                                v-show="index === 0"
                                class="grey--text body-2"
                                >
                                {{ selectedLanguages.length }} selected
                                </span>
                            </template>
                            </v-select>
                            <v-chip
                            v-for="(item, i) in selectedLanguages"
                            :key="i"
                            class="mt-2 me-2"
                            close
                            small
                            color="#023E7D"
                            text-color="white"
                            @click:close="removeLang(selectedLanguages, item)"
                            >
                            {{item}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-textarea
                            v-model="about"
                            :row="2"
                            filled
                            label="About"
                            auto-grow
                            class="some-style"
                            ></v-textarea>
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                            class="mt-4" 
                            color="#023E7D" 
                            depressed 
                            dark 
                            block
                            :loading="profileLoader"
                            @click="updateProfile"
                            >
                                Update Details
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- edit contact and fee info -->
        <v-dialog
        v-model="contactFeeDialog"
        max-width="700"
        >
            <v-card>
                <v-card-title class="card_title">
                Update Contact & Fee
                </v-card-title>
                <v-card-text class="mt-8">
                    <v-row>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="site"
                            label="Website"
                            hide-details="auto"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-select
                            v-model="methods"
                            :items="paymentMethods"
                            label="Select payment method"
                            filled
                            class="some-style"
                            hide-details="auto"
                            multiple
                            >
                            <template v-slot:selection="{ item, index }">
                                <span
                                v-if="index === 0"
                                class="grey--text body-2"
                                >
                                {{ methods.length  }} selected
                                </span>
                            </template>
                            </v-select>
                            <v-chip
                            v-for="(item, i) in methods"
                            :key="i"
                            class="mt-2 me-2"
                            small
                            close
                            color="#023E7D"
                            text-color="white"
                            @click:close="removeMethod(methods, item)"
                            >
                            {{item}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div class="d-flex align-start">
                                <v-checkbox
                                v-model="enabledContigency"
                                dense
                                color="#023E7D"
                                hide-details="auto"
                                class="mr-2 mt-3"
                                ></v-checkbox>
                                <v-text-field
                                v-model="contigency"
                                label="Contigency"
                                suffix="%"
                                placeholder="0"
                                hide-details="auto"
                                persistent-placeholder
                                type="number"
                                :background-color="enabledContigency ? 'light-blue lighten-5' : 'grey lighten-4'"
                                filled
                                class="some-style"
                                :disabled="!enabledContigency"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div class="d-flex align-start">
                                <v-checkbox
                                v-model="enabledHourly"
                                dense
                                color="#023E7D"
                                hide-details="auto"
                                class="mr-2 mt-3"
                                ></v-checkbox>
                                <v-text-field
                                v-model="hourlyRate"
                                label="Hourly Rate"
                                prefix="$"
                                placeholder="0"
                                hide-details="auto"
                                persistent-placeholder
                                type="number"
                                :background-color="enabledHourly ? 'light-blue lighten-5' : 'grey lighten-4'"
                                filled
                                class="some-style"
                                :disabled="!enabledHourly"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <div class="d-flex align-start">
                                <v-checkbox
                                v-model="enabledFixed"
                                dense
                                color="#023E7D"
                                hide-details="auto"
                                class="mr-2 mt-3"
                                ></v-checkbox>
                                <v-text-field
                                v-model="fixedRate"
                                label="Fixed Rate"
                                prefix="$"
                                placeholder="0"
                                hide-details="auto"
                                persistent-placeholder
                                type="number"
                                :background-color="enabledFixed ? 'light-blue lighten-5' : 'grey lighten-4'"
                                filled
                                class="some-style"
                                :disabled="!enabledFixed"
                                ></v-text-field>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                            class="mt-4" 
                            color="#023E7D" 
                            depressed 
                            dark 
                            block
                            :loading="profileLoader"
                            @click="updateContactFee"
                            >
                                Update Contact Info
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- edit Practice info -->
        <v-dialog
        v-model="practiceInfoDialog"
        max-width="700"
        >
            <v-card>
                <v-card-title class="card_title">
                Practice Information
                </v-card-title>
                <v-card-text class="mt-8">
                    <v-row>
                        <v-col cols="12">
                            <p>What states are you licensed to practice?</p>
                        </v-col>
                        <v-col cols="12">
                            <span v-show="licenses?.length === 0" class="caption_text">No licensed information added</span>
                            <v-card v-for="(item, i) in licenses" :key="i" flat outlined class="px-4 py-2 mb-1 d-flex justify-space-between">
                                <div>{{item.state}}</div>
                                <div>{{item.bar_email}}</div>
                                <div>
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                            icon
                                            small
                                            class="mr-2"
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            <v-icon small>mdi-eye</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="pa-4">
                                            <div class="mb-2">
                                                <h6>Bar Email</h6>
                                                <span>{{item.bar_email}}</span>
                                            </div>
                                            <div class="mb-2">
                                                <h6>State</h6>
                                                <span>{{item.state}}</span>
                                            </div>
                                            <div class="mb-2">
                                                <h6>Bar Number</h6>
                                                <span>{{item.bar_number}}</span>
                                            </div>
                                            <div class="mb-2">
                                                <h6>Bar Admission Year</h6>
                                                <span>{{item.bar_admission_year}}</span>
                                            </div>
                                            <div class="mb-2">
                                                <h6>Education</h6>
                                                <span>{{item.education}}</span>
                                            </div>
                                        </v-list>
                                    </v-menu>
                                    <v-btn
                                    icon
                                    small
                                    @click="removeLicense(licenses, item)"
                                    >
                                    <v-icon small>mdi-trash-can</v-icon>
                                    </v-btn>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col 
                        v-show="licensesFormStatus"
                        cols="12" 
                        lg="6"
                        >
                            <v-autocomplete
                            v-model="barState"
                            label="State"
                            item-text="name"
                            hide-details="auto"
                            filled
                            :items="states"
                            return-object
                            dense
                            class="some-style"
                            >
                            <template v-slot:selection="{item}">
                                <span class="text-capitalize">{{ item.name }}</span>
                            </template>
                            <template v-slot:item="{item}">
                                <span class="text-capitalize">{{ item.name }}</span>
                            </template>
                            </v-autocomplete>
                        </v-col>
                        <v-col
                        v-show="licensesFormStatus === true" 
                        cols="12" 
                        lg="6" 
                        >
                            <v-text-field
                            v-model="barEmail"
                            label="Verify Bar Email"
                            hide-details="auto"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col 
                        v-show="licensesFormStatus === true"
                        cols="12" 
                        lg="3"
                        >
                            <v-text-field
                            v-model="barNum"
                            label="Bar Number"
                            hide-details="auto"
                            type="number"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col 
                        v-show="licensesFormStatus === true"
                        cols="12" 
                        lg="3"
                        >
                            <v-text-field
                            v-model="barYear"
                            label="Bar Admission Year"
                            hide-details="auto"
                            type="number"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col 
                        v-show="licensesFormStatus === true"
                        cols="12" 
                        lg="6"
                        >
                            <v-text-field
                            v-model="education"
                            label="Education"
                            hide-details="auto"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6" class="pt-5 d-flex justify-space-between">
                            <v-btn 
                            v-show="licensesFormStatus === false"
                            color="#023E7D" 
                            text
                            @click="licensesFormStatus = !licensesFormStatus"
                            >
                                <v-icon
                                    left
                                    dark
                                >
                                    mdi-plus-circle-outline
                                </v-icon>
                                Add license area 
                            </v-btn>
                            <div class="justify-start align-center">
                                <v-btn 
                                v-show="licensesFormStatus === true"
                                color="#023E7D" 
                                class="me-2 white--text"
                                elevation="0"
                                :disabled="!licenseCheck"
                                @click="addLicense"
                                >
                                    Add license
                                </v-btn>
                                <v-btn v-show="licensesFormStatus" icon @click="licensesFormStatus = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p>What type of attorney are you?</p>
                            <v-autocomplete
                            v-model="types"
                            :items="roles"
                            :rules="[rules.objRequired]"
                            label="Select type of attorney"
                            filled
                            multiple
                            return-object
                            hide-details="auto"
                            class="some-style"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p>What's your practice area?</p>
                            <v-autocomplete
                            v-model="areas"
                            :items="practiceList"
                            label="Select practice area"
                            :item-text="item => item"
                            :rules="[rules.objRequired]"
                            filled
                            multiple
                            return-object
                            hide-details="auto"
                            class="some-style"
                            >
                            <template v-slot:selection="{ item, index }">
                                <span
                                v-if="index === 0"
                                class="grey--text body-2"
                                >
                                {{ areas.length  }} selected
                                </span>
                            </template>
                            </v-autocomplete>
                            <v-chip
                            v-for="(item, i) in areas"
                            :key="i"
                            class="mt-2 me-2"
                            small
                            close
                            color="#023E7D"
                            text-color="white"
                            @click:close="removeArea(areas, item)"
                            >
                            {{item}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12">
                            <v-btn 
                            class="mt-4" 
                            color="#023E7D" 
                            depressed 
                            dark 
                            block
                            :loading="profileLoader"
                            @click="updatePracticeInfo"
                            >
                                Update Practice Info
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Time Picker Dialog -->
        <TimePicker
        v-model="timerDialog"
        @time-selected="updateTime"
        />


        <!-- snackbar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        top
        right
        >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import Sidebar from '../../components/attorney/SidebarAttorney.vue'
import Navbar from '../../components/attorney/NavbarAttorney.vue'

import moment from 'moment'
import TimePicker from '../../components/BoostTimePicker.vue';
import { StripeElementCard } from '@vue-stripe/vue-stripe';

  export default {
    
    created: function () {
        if(this.profileDetails.has_completed_stripe_account_setup == true) {
            this.getStripe()
        }
        this.getPractice()
        this.getAllStates()
        // this.getCurrentTime();
        this.$store.dispatch('getBoostDetails')
    },

    mounted () {
      this.calculateDateForAge()
      this.boostState()
      this.showRatings = !this.profileDetails.disable_review_ratings
    },

    components: {
        Sidebar, Navbar, TimePicker, StripeElementCard
    },

    data () {
      this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY;
      return {
        firstName: '',
        lastName: '',
        selectedLanguages: [],
        languages: ['English', 'Spanish', 'French'],
        phone: '',
        about: '',
        site: '',
        enabledContigency: false,
        enabledHourly: false,
        enabledFixed: false,
        contigency: '',
        hourlyRate: '',
        fixedRate: '',
        paymentMethods: ['Bank Deposit', 'Card', 'Cash'],
        methods: [],
        menu: false,
        date: null,
        rules: {
          required: value => !!value || 'Required.',
          objRequired: v => Boolean(Object.keys(v || {})[0]) || "Required.",
          timeValidation: value => this.validateBoostFrom(value) || 'Start time must be in the future if the date is today.'
        },
        boostValid: true,
        
        PaymentInfo: false,
        stripeLoader: false,
        bank: '',
        cosName: '',
        banks: [],
        setupStripe: false,
        website: '',
        address: '',
        state: '',
        postalCode: '',

        snackbar: false,
        message: '',
        bgColor: '',

        stripeUrl: '',
        completeSetup: false,

        stripeDetails: {},
        secondaryCheck: false,
        deleteStripeAcct: false,
        profileLoader: false,
        maxDate: '',

        contactFeeDialog: false,
        practiceInfoDialog: false,
        profileInfoDialog: false,
        barCitySearch: null,
        licensesFormStatus: false,
        education: '',
        barYear: '',
        barNum: '',
        barEmail: '',
        barState: null,
        states: [],
        licenses: [],
        roles: ['Criminal Defense', 'Civil Plaintiff', 'Civil Defense', 'Transactional'],
        types: null,
        practiceList: [],
        practice: ['Bankruptcy', 'Business', 'Constitutional', 'Criminal Defense', 'Employment and Labor', 'Entertainment', 'Estate Planning', 'Family', 'Immigration', 'Intellectual Property', 'Personal Injury', 'Tax'],
        areas: [],
        boostProfile: false,
        boostTypes: [
            {value: 'hourly', duration: 3, cost: 3},
            {value: 'daily', duration: 5, cost: 25}
        ],
        boostStart: '',
        boostEnd: '',
        boostFrom: '',
        boostTo: '',
        timerDialog: false,
        token: null,
        boostType: {value: 'hourly', duration: 3, cost: 1},
        boostMenu: false,
        boostDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        boostSlider: null,
        boostMax: 3,
        boostMin: 0,
        boostCost: 0,
        loading: false,
        boostToggle: false,
        showRatings: false,
        rateLoader: false,
        imgPlaceholder: '',
        img: null,
        imgUrl: '',
        selectedFile: null,
        imgloader: false
      }
    },

    methods: {
        onFileSelected(event){
            try{
                this.selectedFile = event.target.files[0]
                this.imgPlaceholder = URL.createObjectURL(this.selectedFile)
                const reader = new FileReader();
                reader.onload = () => {
                    this.img = reader.result
                };
                reader.readAsDataURL(this.selectedFile);
                this.imgUpload()
            } catch {
                this.img = null
            }
        },

        imgUpload() {
            // let formstatus = this.$refs.form2.validate()
            // if(formstatus != true) return

            this.imgloader = true
            if(this.selectedFile != null) {
            this.$store.dispatch('imgCloudUpload', this.selectedFile)
            .then((transformedImageUrl) => {
                this.imgUrl = transformedImageUrl
                this.updateImgUrl()
            })
            .catch(() => {
                console.log('an error occured')
            })
            } else {
                this.updateImgUrl()
            }
        },

        updateImgUrl() {
            this.$store.dispatch('updateAttorneyProfile', {
                image_url: this.imgUrl,
            })
            .then(() => {
            this.imgloader = false
            this.$store.dispatch('getUserDetails')
            this.snackbar = true
            this.message = 'Profile successfully updated'
            this.bgColor = 'success'
            this.profileInfoDialog = false
            })
            .catch((error) => {
            this.imgloader = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        boostSliderState() {
            this.boostCost = this.boostType.cost * this.boostSlider
        },
        boostState() {
            this.boostSlider = this.boostType.duration
            this.boostMax = this.boostType.duration
            this.boostCost = this.boostType.cost * this.boostSlider
        },
        // getCurrentTime() {
        //     this.boostFrom = moment().format('hh:mm A');
        // },
        validateBoostFrom(value) {
            const currentDate = moment().format('YYYY-MM-DD');
            const currentTime = moment();
            const selectedTime = moment(value, 'hh:mm A');
            
            if (this.boostDate === currentDate && selectedTime.isBefore(currentTime)) {
                return false;
            }
            return true;
        },
        submit () {
            let formstatus = this.$refs.boostForm.validate()
            if(formstatus != true) return
            this.loading = true
            this.$refs.elementRef.submit();
        },
        tokenCreated (token) {
            this.loading = false
            this.token = token;
            this.sendTok()
        },
        sendTok() {
            this.loading = true
            console.log(this.token.id)
            let startDate = ''
            let endDate = ''
            if(this.boostType.value == 'daily') {
                startDate = moment(this.boostDate).format('MM-DD-YYYY')
                endDate = moment(startDate, "MM-DD-YYYY").add(this.boostSlider, 'days').format("MM-DD-YYYY")
            } else {
                startDate = moment(this.boostDate).format('MM-DD-YYYY')
                endDate = moment(this.boostDate).format('MM-DD-YYYY')
            }
            let startTime = moment(this.boostFrom, 'hh:mm A').format('HH:mm')
            let endTime = moment(startTime, "HH:mm").add(this.boostSlider, 'hours').format("HH:mm")
            this.$store.dispatch('createBoostAds', {
                boost_type: this.boostType.value,
                amount: this.boostCost,
                token_id: this.token.id,
                start_date: startDate,
                end_date: endDate,
                start_time: startTime,
                end_time: endTime
            })
            .then(() => {
            this.loading = false
            this.snackbar = true
            this.message = 'Profile boosted successfully'
            this.bgColor = 'success'
            this.$store.dispatch('getBoostDetails')
            this.boostToggle = false
            })
            .catch((error) => {
            this.loading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },
        openTimePicker(input) {
            this.selectedInput = input;
            this.timerDialog = true;
        },

        updateTime(time) {
            this[this.selectedInput] = time;
        },

        profileInfo() {
            this.firstName = this.userDetails.user.first_name
            this.lastName = this.userDetails.user.last_name
            this.phone = this.userDetails.user.phone
            this.selectedLanguages = this.userDetails.user.spoken_language
            this.date = (new Date(Date.now(this.userDetails.user.dob) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            this.profileInfoDialog = true
            this.about = this.userDetails.profile.contact_info.about
        },

        practiceDetails() {
            this.practiceInfoDialog = true
            this.licenses = this.userDetails.profile.licensed_areas;
            this.types = this.userDetails.profile.attorney_type;
            this.areas = this.userDetails.profile.practice_area;
            console.log(this.userDetails.profile.attorney_type)
            console.log(this.userDetails.profile.practice_area)
            // let practiceAreas  = this.userDetails.profile.practice_area
            // practiceAreas.forEach(areas=>{
            //     this.areas.push({
            //         name: areas,
            //         description: ''
            //     })
            // })
        },

        getAllStates() {
            this.$store.dispatch('getStates')
            .then((response) => {
                console.log(response)
                this.states = response.data.data
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgcolor = 'error'
            })
        },

        addLicense() {
            if(this.licensesFormStatus == true) {
                this.licenses.push({
                state: this.barState.name + ' ' + this.barState.abbreviation,
                bar_email: this.barEmail,
                bar_number: this.barNum,
                bar_admission_year: this.barYear,
                education: this.education
                })

                this.barState = null
                this.barEmail = ''
                this.barNum = ''
                this.barYear = ''
                this.education = ''
                this.licensesFormStatus = false
            }
        },

        removeLicense(licenses, item) {
            var index = licenses.indexOf(item);
            if (index > -1) {
                licenses.splice(index, 1);
            }
            return licenses;
        },

        contactFee() {
            this.site = this.userDetails.profile.contact_info.website;
            this.contigency = this.paymentPlan.contigency;
            this.hourlyRate = this.paymentPlan.hourly_rate;
            this.fixedRate = this.paymentPlan.fixed_rate;
            this.methods = this.paymentPlan.payment_method;
            this.contactFeeDialog = true
        },

        updateProfile() {
            this.profileLoader = true
            this.$store.dispatch('updateAttorneyProfile', {
                first_name: this.firstName,
                last_name: this.lastName,
                phone: this.phone,
                dob: new Date(this.date),
                spoken_language: this.selectedLanguages,
                about: this.about
            })
            .then(() => {
            this.profileLoader = false
            this.$store.dispatch('getUserDetails')
            this.snackbar = true
            this.message = 'Profile successfully updated'
            this.bgColor = 'success'
            this.profileInfoDialog = false
            })
            .catch((error) => {
            this.profileLoader = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        updateContactFee() {
            this.profileLoader = true
            this.$store.dispatch('updateAttorneyProfile', {
                contigency: this.contigency,
                hourly_rate: this.hourlyRate,
                fixed_rate: this.fixedRate,
                payment_method: this.methods,
                website: this.site,
            })
            .then(() => {
            this.profileLoader = false
            this.$store.dispatch('getUserDetails')
            this.snackbar = true
            this.message = 'Profile successfully updated'
            this.bgColor = 'success'
            this.contactFeeDialog = false
            })
            .catch((error) => {
            this.profileLoader = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        updatePracticeInfo() {
            this.profileLoader = true
            this.$store.dispatch('updateAttorneyProfile', {
                licensed_areas: this.licenses,
                attorney_type: this.types,
                practice_area: this.areas
            })
            .then(() => {
            this.profileLoader = false
            this.$store.dispatch('getUserDetails')
            this.snackbar = true
            this.message = 'Profile successfully updated'
            this.bgColor = 'success'
            this.practiceInfoDialog = false
            })
            .catch((error) => {
            this.profileLoader = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        calculateDateForAge() {
            const dateFor18YearsAgo = moment().subtract(18, 'years');
            this.maxDate = dateFor18YearsAgo.format('YYYY-MM-DD');
        },

        getPractice() {
            this.loading = true
            this.$store.dispatch('getPractice', {
            })
            .then((response) => {
            this.loading = false
            let practiceAreas  = response.data.data
            practiceAreas.forEach(areas=>{
                this.practiceList.push(`${areas.name}`)
            })
            })
            .catch((error) => {
            this.loading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        removeLang(selectedLanguages, item) {
            var index = selectedLanguages.indexOf(item);
            if (index > -1) {
                selectedLanguages.splice(index, 1);
            }
            return selectedLanguages;
        },

        removeType(types, item) {
            var index = types.indexOf(item);
            if (index > -1) {
                types.splice(index, 1);
            }
            return types;
        },

        removeArea(areas, item) {
            var index = areas.indexOf(item);
            if (index > -1) {
                areas.splice(index, 1);
            }
            return areas;
        },

        deleteSetup() {
            this.deleteStripeAcct = true
            this.$store.dispatch('deleteStripeSetup')
            .then((response) => {
                console.log(response)
                this.stripeDetails = {}
                this.snackbar = true
                this.message = 'Stripe account deleted'
                this.bgColor = 'success'
                this.deleteStripeAcct = false
                this.secondaryCheck = false
                this.$store.dispatch('getUserDetails')
                // this.$store.dispatch('getUser')
            })
            .catch((error) => {
                this.deleteStripeAcct = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        getStripe() {
            console.log('Get Stripe Details')
            this.$store.dispatch('getStripeAcct')
            .then((response) => {
                let stripeState =  response.data.data
                if('bank_name' in stripeState) {
                    this.stripeDetails = {
                        bank_name: response.data.data.bank_name, 
                        brand: response.data.data.currency, 
                        last4: response.data.data.last4
                    }
                }
                console.log('Stripe Details')
                console.log(this.stripeDetails)
                console.log(response.data.data)
            })
            .catch(() => {
                this.snackbar = true
                this.message = 'Get stripe details failed'
                this.bgColor = 'error'
            })
        },
        
        createStripe() {
            this.stripeLoader = true
            this.$store.dispatch('createStripeAccount', {
                website_url: this.website,
                address_line1: this.address,
                state: this.state,
                address_postal_code: this.postalCode
            })
            .then((response) => {
                console.log(response)
                this.stripeLoader = false
                this.stripeUrl = response.data.data.url
                console.log(this.stripeUrl)
                this.snackbar = true
                this.message = 'Stripe account setup'
                this.bgColor = 'success'
                this.completeSetup = true
                this.$store.dispatch('getUserDetails')
            })
            .catch((error) => {
                this.stripeLoader = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },
    },

    computed: {
      userDetails() {
        return this.$store.state.userData;
      },

      profileDetails() {
        return this.$store.state.userData.profile;
      },

      planDetails() {
        return this.$store.state.userData.plan;
      },

      paymentPlan() {
        return this.$store.state.userData.profile.payment_plan;
      },

      contactInfo() {
        return this.$store.state.userData.profile.payment_plan.contact_info;
      },

      licenseCheck() {  
        return (
            this.education != '' && this.barYear != '' && this.barNum != '' && this.barState != null
        )
      },

      profileData() {
        return this.$store.state.userData.profile;
      },

      boostDetails() {
        return this.$store.state.boostData;
      },
    }

  }
</script>

<style scoped>
    .main_topic {
        font-size: 20px;
        color: #023E7D;
    }
    .pageName {
        color: #023E7D;
    }
    .topic {
        color: #023E7D;
        font-size: 14px;
    }
    .placeholder_text {
        color: #A7A7A7;
    }
    .btn_text {
        color: #666666;
        font-size: 14px;
    }
    .btn_text-light {
        color: #fff;
        font-size: 14px;
    }
    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }
    .card_figure-text {
        color: #023E7D;
    }
    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }
    .attorney_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }
    .attorney_card-box {
        height: 34px;
    }
    .card_dheading {
        color: #FFFFFF;
        margin-top: 25px;
        margin-left: 8px;
    }
    .list_text {
      font-family: 'Poppins', sans-serif;
      font-size: 14px;
    }
    .card_lheading {
        color: #023E7D;
    }
    .card_lsubheading {
        color: #585F6C;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }
    .card_dsubheading {
        color: #FFFFFF;
        font-size: 12px;
    }
    .body_llink {
        color: #023E7D;
        font-size: 14px;
        text-decoration: none;
    }
    .body_dlink {
        color: #FFFFFF;
        font-size: 14px;
        text-decoration: none;
    }
    .info_section {
        margin-top: 25px;
    }
    .profile_info {
        background-color: #FBFBFB;
        border-radius: 5px;
    }
    .list_section {
        margin-top: 15px;
    }
    .attorney_list-title {
        color: #023047;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
    }
    .attorney_list-subtitle {
        font-size: 13px;
    }
    .title_bg {
        background-color: #023E7D;
        color: #fff;
    }
    .profile_details {
        background-color: #FBFBFB;
    }
    .details_card {
        color: #023E7D;
    }
    .text_caption {
        font-size: 12px;
        color: grey;
    }
    .boost_cost {
        font-size: 40px;
        font-weight: bold;
    }
    .boost_caption {
        margin-top: 2px;
        font-size: 12px;
    }
    .dollar_sign {
        font-size: 20px;
    }
    .boost_title {
        font-size: 23px;
        font-weight: 900;
    }
    .boost_date {
        font-size: 23px;
        font-weight: 400;
    }
    .plan_name {
        font-size: 18px;
        color: #023E7D;
    }
    .plan_text {
        font-size: 12px;
        font-weight: 400;
        color: #666666;
    }
    .avatar_box {
        cursor: pointer;
    }
</style>