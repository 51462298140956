<template>
  <!-- <hello-world /> -->
  <div class="px-2">
    <v-app-bar
      color="white"
      light
      flat
    >

      <div>
        <v-img class="logo_img"
          src="../../assets/legallync-logo-dark.png"
        ></v-img>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>
    <v-container class="auth_container">
        <h2 class="auth_title">Client Setup</h2>
        <p class="auth_subtitle font-weight-medium">LegalLync where legal connections are made!</p>
        <v-row class="auth_row">
            <v-col cols="12" lg="2"></v-col>
            <v-col cols="12" lg="8">
                <v-form
                ref="form"
                v-model="valid"
                lazy-validation
                class="auth_form"
                >
                    <v-row>
                        <v-col cols="12">
                            <input type="file" ref="file" style="display: none" @change="onFileSelected" >
                            <v-avatar class="avatar_tag" rounded :size="avatarSize" 
                                        @click="$refs.file.click()">
                                <v-img :src="imgPlaceholder" alt="" accept="image/*" aspect-ratio="1.1"></v-img>
                            </v-avatar>
                        </v-col>
                        <v-col cols="12" class="py-0">
                            <p>What is your name?</p>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="firstName"
                            label="First Name"
                            :rules="[rules.required]"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="lastName"
                            label="Last Name"
                            :rules="[rules.required]"
                            filled
                            dense
                            hide-details="auto"
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="email"
                            label="Email"
                            :rules="[rules.required, rules.emailRules]"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-text-field
                            v-model="password"
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show ? 'text' : 'password'"
                            label="Password"
                            filled
                            dense
                            class="some-style"
                            @click:append="show = !show"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p class="font-weight-medium">When is your date of birth?</p>
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value.sync="date"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFormatted"
                                    label="MMDDYYYY"
                                    append-icon="mdi-calendar-blank-outline"
                                    :rules="[rules.required, ageCheck]"
                                    filled
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    class="some-style"
                                ></v-text-field>
                                </template>
                                <v-date-picker
                                v-model="date"
                                no-title
                                scrollable
                                >
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menu.save(date)"
                                >
                                    OK
                                </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p class="font-weight-medium">What is your phone number?</p>
                            <v-text-field
                            v-model="phone"
                            label="Phone"
                            :rules="[rules.required]"
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <!-- :item-text="item => item.primary_city+'  '+ item.state" -->
                        <v-col cols="12" lg="6">
                            <p class="font-weight-medium">Where do you live?</p>
                            <v-autocomplete
                            v-model="zipCode"
                            label="Emter Zip Code"
                            :rules="[rules.required]"
                            @keyup="querySelections"
                            return-object
                            type="number"
                            item-text="zip_code"
                            filled
                            :items="zipCodeList"
                            :loading="isLoading"
                            dense
                            class="some-style"
                            @change="zipCodeSelected"
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6" class="d-flex align-end">
                            <v-text-field
                            v-model="city"
                            label="City, State"
                            :rules="[rules.required]"
                            readonly
                            filled
                            dense
                            class="some-style"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p class="font-weight-medium">What language do you speak?</p>
                            <v-select
                            v-model="langs"
                            :items="items"
                            label="Select Language"
                            :rules="[rules.objRequired]"
                            filled
                            multiple
                            hide-details="auto"
                            class="some-style"
                            >
                            <template v-slot:selection="{ item, index }">
                                <span
                                v-if="index === 0"
                                class="grey--text body-2"
                                >
                                {{ langs.length  }} selected
                                </span>
                            </template>
                            </v-select>
                            <v-chip
                            v-for="(item, i) in langs"
                            :key="i"
                            class="mt-2 me-2"
                            close
                            small
                            color="#023E7D"
                            text-color="white"
                            @click:close="removeLang(langs, item)"
                            >
                            {{item}}
                            </v-chip>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <p class="font-weight-medium">How do you want to be contacted?</p>
                            <div class="d-flex justify-start">
                                <div class="check_container">
                                    <v-checkbox 
                                    v-model="contactMethod"
                                    color="#023E7D"
                                    value="phone"
                                    dense
                                    hide-details="auto"
                                    >
                                        <template v-slot:label>
                                            <div class="font-weight-medium check_text">
                                            Phone
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                                <div class="check_container">
                                    <v-checkbox 
                                    v-model="contactMethod"
                                    color="#023E7D"
                                    value="email"
                                    dense
                                    hide-details="auto"
                                    >
                                        <template v-slot:label>
                                            <div class="font-weight-medium check_text">
                                            Email
                                            </div>
                                        </template>
                                    </v-checkbox>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="12">
                            <v-btn
                                color="#023E7D"
                                :loading="loading"
                                :disabled="loading"
                                large
                                block
                                class="mt-5 reg_btn white--text"
                                elevation="0"
                                @click="imgUpload"
                            >
                                Create profile
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
            <v-col lg="2"></v-col>
        </v-row>
    </v-container>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="bgColor"
      top
      right
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
          <v-btn
              color="white"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
              Close
          </v-btn>
      </template>
    </v-snackbar>
    
  </div>
</template>

<script>

  export default {
    props: ['clientEmail', 'clientPassword',],

    created: function () {
        // this.getCities()
    },

    components: {
    },
    data: vm => ({
        menu: false,
        valid: true,
        loading: false,
        zipLoading: false,
        isLoading: false,
        show: false,
        role: 'client',
        email: '',
        password: '',
        rules: {
          required: value => !!value || 'Required.',
          objRequired: v => Boolean(Object.keys(v || {})[0]) || "Required.",
          min: v => v.length >= 8 || 'Minimum 8 characters password',
          emailRules: v => /.+@.+\..+/.test(v) || 'Email must be valid',
        },
        firstName: '',
        lastName: '',
        zipCode: [],
        city: null,
        contactMethod: ['phone', 'email'],
        phone: '',
        imgPlaceholder: 'https://res.cloudinary.com/dzsshr0iz/image/upload/v1652961985/Ilerah/avatar-ph_pce6l3.jpg',
        selectedFile: null,
        avatarSize: 100,
        img: null,
        imgUrl: '',
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
        items: ['English', 'Spanish', 'French'],
        langs: [],

        snackbar: false,
        message: '',
        bgColor: '',
        cityList: [],
        zipCodeList: [],
        citySearch: null,
        cities: [],
        location: {},
        
    }),

    watch: {
        date () {
            this.dateFormatted = this.formatDate(this.date)
        },

        citySearch (val) {
            val && val !== this.city && this.querySelections(val)
        },
    },

    methods: {
        ageCheck() {
            let selected = new Date(this.date);
            const today = new Date();
            
            let ageDifference = today.getFullYear() - selected.getFullYear();
            const monthDiff = today.getMonth() - selected.getMonth();
            
            if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < selected.getDate())) {
                ageDifference--;
            }

            if (ageDifference >= 18) {
                return true;
            } else {
                return 'You need to be 18+ to register.';
            }
        },

        querySelections(v) {
            this.isLoading = true

            this.$store.dispatch('getCities', v.srcElement._value)
            .then((response) => {
                this.isLoading = false
                this.zipCodeList = response.data.data
                // this.cities.forEach(cities=>{
                //     this.cityList.push({
                //         location: cities.primary_city+ ' ' + cities.state,
                //         primary_city: cities.primary_city,
                //         state: cities.state,
                //     })
                // })
            })
            .catch((error) => {
                this.isLoading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        zipCodeSelected() {
            this.location = this.zipCode
            this.city = `${this.zipCode.primary_city}, ${this.zipCode.state}`
        },

        formatDate (date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${month}/${day}/${year}`
        },

        removeLang(langs, item) {
            console.log(item);
            console.log(langs);
            var index = langs.indexOf(item);
            if (index > -1) {
                langs.splice(index, 1);
            }
            return langs;
        },

        onFileSelected(event){
            try{
                this.selectedFile = event.target.files[0]
                this.imgPlaceholder = URL.createObjectURL(this.selectedFile)
                const reader = new FileReader();
                reader.onload = () => {
                    this.img = reader.result
                };
                reader.readAsDataURL(this.selectedFile);

            } catch {
                this.img = null
            }
        },

        imgUpload() {
            let formstatus = this.$refs.form.validate()
            if(formstatus != true) return

            this.loading = true
            if(this.selectedFile != null) {
            this.$store.dispatch('imgCloudUpload', this.selectedFile)
            .then((response) => {
                this.imgUrl = response.data.secure_url
                this.createProfile()
            })
            .catch(() => {
                console.log('an error occured')
                // this.loading = false
                // this.snackbar = true
                // this.message = error.response.data.error
                // this.bgColor = 'error'
            })
            } else {
                this.createProfile()
            }
        },

        createProfile() {
            console.log(this.date)
            console.log(this.dateFormatted)
            this.loading = true
            this.$store.dispatch('registration', {
            img: this.imgUrl,
            role: this.role,
            email: this.email,
            password: this.password,
            firstName: this.firstName,
            lastName: this.lastName,
            zipCode: this.location.zip_code,
            city: this.location.primary_city,
            state: this.city,
            country: 'US',
            languages: this.langs,
            dob: new Date(this.date),
            phone: this.phone,
            contactMethod: this.contactMethod
            })
            .then(() => {
            this.loading = false
            let userDetail = 'client'
            this.$store.commit('setUser', userDetail);
            })
            .catch((error) => {
            this.loading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },

        getZipCodes() {
            this.zipLoading = true
            this.$store.dispatch('getZipCodes', this.city)
            .then((response) => {
            this.zipLoading = false
            this.zipCodeList = response.data.data
            })
            .catch((error) => {
            this.zipLoading = false
            this.snackbar = true
            this.message = error.response.data.error
            this.bgColor = 'error'
            })
        },
    },

    computed: {
        
    }

  }
</script>

<style scoped>
    .logo_img {
        width: 160px;
    }

    .auth_container {
        margin-top: 5vh;
    }

    .auth_title {
        color: #023E7D;
    }

    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }

    .auth_subtitle {
        color: #585F6C;
    }

    .auth_row {
        margin-top: 40px;
    }

    .check_container {
        border: solid 1px #023E7D;
        border-radius: 3px;
        background-color: #F6FAFF;
        padding: 5px 10px;
        margin-right: 20px;
        width: 150px;
        height: 52px;
    }

    .check_text {
        color: #023E7D;
    }

    .reg_btn {
        margin-bottom: 40px;
    }

    .avatar_tag {
        cursor: pointer; 
    }

</style>