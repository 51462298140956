<template>
  <div>
    <v-btn
    color="#023E7D"
    rounded
    small
    dark
    class="pa-4"
    elevation="0"
    @click="dialog = true"
    >
    <v-icon
        left
        small
    >
        mdi-plus-circle-outline
    </v-icon>
    Create Appointment
    </v-btn>

    <v-dialog
      v-model="dialog"
      max-width="700"
    >
      <v-card>
        <div class="title_bg">
            <v-card-title class="card_title font-weight-bold">
            New Appointment
            </v-card-title>
        </div>
        <v-card-text>
            <v-stepper class="elevation-0" v-model="e1">
                <v-stepper-header class="elevation-0">
                <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                    color="#023E7D"
                >
                    Select Case
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                    color="#023E7D"
                >
                    Select Attorney
                </v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card
                    class="mb-12"
                    flat
                    min-height="200px"
                    >
                        <div>
                            <span>What case do want to create an appointment for?</span>
                            <v-list class="my-1 py-0">
                                <v-list-item 
                                v-for="(item, i) in allCase"
                                :key="i"
                                class="px-1"
                                @click="selectItem(item)"
                                >
                                    <v-list-item-avatar color="#DCE8F8">
                                        <v-icon color="#C3D6EF" dark>
                                        mdi-briefcase
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title class="d-flex justify-space-between align-center">
                                            <span>{{item.case_name}}</span>
                                            <div class="d-flex align-center">
                                            <span v-show="item.status == 'retained'" class="me-8 attorney_list-text">Retained</span>
                                            <span v-show="item.status == 'matched'" class="me-8">{{item.match_requests.length}} Matched</span>
                                        </div>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                        
                    </v-card>
                    
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-card
                    class="mb-12"
                    flat
                    min-height="200px"
                    >
                        <v-card-text>
                            <span>Choose An Attorney</span>
                            <v-progress-linear
                            v-show="attorneySelectedLoader"
                            :indeterminate="attorneySelectedLoader"
                            color="primary"
                            ></v-progress-linear>
                            <div v-show="selectedCaseStatus === 'retained'">
                                <v-skeleton-loader
                                v-if="matchRetainLoader === true"
                                type="list-item-avatar-two-line"
                                ></v-skeleton-loader>
                                <v-list v-else>
                                    <v-list-item-group
                                        v-model="selectedListItem"
                                        color="primary"
                                    >
                                    <v-list-item 
                                    v-for="(item, i) in retainAttorneysList"
                                    :key="i"
                                    class="px-1" 
                                    @click="selectedAttorney(item)"
                                    >
                                        <v-list-item-content class="py-1">
                                            <div class="attorney_card d-flex justify-space-between align-center">
                                                <div class="attorney_card-box d-flex align-center">
                                                    <v-avatar rounded size="45" class="me-4">
                                                        <img
                                                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                                                            alt="John"
                                                        >
                                                    </v-avatar>
                                                    <div>
                                                        <h4 class="mb-2 attorney_list-title">{{item.attorney.first_name}} {{item.attorney.last_name}}</h4>
                                                        <span class="attorney_list-subtitle">
                                                            <span
                                                            class="text-capitalize"
                                                            v-for="(item, i) in item.attorney_profile.attorney_type"
                                                            :key="i"
                                                            >
                                                                {{item}} .
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </div>
                            <div v-show="selectedCaseStatus === 'matched'">
                                <v-skeleton-loader
                                v-if="matchRetainLoader === true"
                                type="list-item-avatar-two-line"
                                ></v-skeleton-loader>
                                <v-list v-else>
                                    <v-list-item-group
                                        v-model="selectedListItem"
                                        color="primary"
                                    >
                                    <v-list-item 
                                    v-for="(item, i) in matchAttorneysList"
                                    :key="i"
                                    class="px-1" 
                                    @click="selectedAttorney(item)"
                                    >
                                        <v-list-item-content class="py-1">
                                            <div class="attorney_card d-flex justify-space-between align-center">
                                                <div class="attorney_card-box d-flex align-center">
                                                    <v-avatar rounded size="45" class="me-4">
                                                        <img
                                                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                                                            alt="John"
                                                        >
                                                    </v-avatar>
                                                    <div>
                                                        <h4 class="mb-2 attorney_list-title">{{item.attorney.first_name}} {{item.attorney.last_name}}</h4>
                                                        <span class="attorney_list-subtitle">
                                                            <span
                                                            class="text-capitalize"
                                                            v-for="(item, i) in item.attorney_profile.attorney_type"
                                                            :key="i"
                                                            >
                                                                {{item}} .
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </v-list-item-content>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card-text>
        <v-card-actions>
            <v-btn v-show="e1 === 2" color="#023E7D" class="ms-4" @click="backDialog" text>
                <v-icon
                left
                dark
                >
                mdi-arrow-left
                </v-icon>
                Back
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- book appointments -->
    <v-dialog
        v-model="bookDialog"
        max-width="650"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Book Appointment
                </v-card-title>
            </div>
            <v-card-text class="mt-8">
                <div class="mb-2 attorney_card-box d-flex align-center">
                    <v-avatar rounded size="45" class="me-4">
                        <img
                            src="https://cdn.vuetifyjs.com/images/john.jpg"
                            alt="John"
                        >
                    </v-avatar>
                    <div>
                        <h4 class="attorney_list-title">{{attorneyDetails.first_name}} {{attorneyDetails.last_name}}</h4>
                        <span class="attorney_list-subtitle">
                            <span
                            class="text-capitalize"
                            v-for="(item, i) in attorneyprofile.attorney_type"
                            :key="i"
                            >
                                {{item}} .
                            </span>
                        </span>
                    </div>
                </div>
                <p class="mb-8 mt-4">Please pick any of the available days on the calender, note the days set by the attorney.</p>
                <v-row>
                    <v-col lg="6">
                        <v-date-picker
                        v-if="allowedLoader"
                        color="#023E7D"
                        v-model="availableDate"
                        :allowed-dates="allowedDates"
                        :to-allowed-dates="(val) => toAllowedDates(val, index)"
                        :picker-date.sync="pickerDateNext"
                        full-width
                        :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        @change="getAvailableTimes"
                        ></v-date-picker>
                        <v-card
                        v-else
                        flat
                        height="300"
                        class="d-flex justify-center align-center"
                        >
                            <v-progress-circular
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                        </v-card>
                    </v-col>
                    <v-col lg="6">
                        <h4>Pick a time</h4>
                        <span>Set time(s) for the selected date(s) on the calendar</span>
                        <div class="py-2">
                            <v-select
                            v-model="selectedTime"
                            :items="availableTimes"
                            :item-text="item => item.from+' - '+ item.to"
                            filled
                            label="Available Times"
                            return-object
                            dense
                            :loading="loading"
                            :disabled="availableDate != '' ? false : true"
                            class="some-style"
                            ></v-select>
                        </div>
                        <div class="text-end">
                            <v-btn
                            color="#023E7D"
                            class="stepper_btn"
                            depressed
                            :loading="bookAppointmentloader"
                            @click="bookAppointment"
                            >
                            Book Appointment
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

    <!-- snackbar -->
    <v-snackbar
    v-model="snackbar"
    :color="bgColor"
    top
    center
    >
    {{ message }}
    <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
            Close
        </v-btn>
    </template>
    </v-snackbar>

  </div>
</template>

<script>
import moment from 'moment'

  export default {
    data () {
      return {
        dialog: false,
        e1: 1,
        selectedListItem: null,
        menu: false,
        date: '',
        checkbox: false,
        checkbox2: false,
        loading: false,
        time: {},
        caseId: '',
        attorneyId: '',
        availability: [],
        daysAvailable: [],
        selectedCaseStatus: '',
        matchRetainLoader: false,
        retainAttorneysList: [],
        matchAttorneysList: [],
        snackbar: false,
        message: '',
        bgColor: '',
        attorneySelected: false,
        attorneySelectedLoader: false,
        pickerDateNext: null,
        availableTimes: [],
        bookAppointmentloader: false,
        allowedLoader: true,
        allowedDateList: [],
        availableDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        selectedTime: {},
        bookDialog: false,
        attorneyDetails: {},
        attorneyprofile: {}
      }
    },

    watch: {
        async pickerDateNext (newval) {
            this.allowedLoader = false
            let separatedItem = newval.split('-');
            let selectedYear = separatedItem[0]
            let selectedMonth = separatedItem[1]
            this.loading = true;
            await this.$store.dispatch('getBookableDates', {
                year: selectedYear,
                month: selectedMonth,
                attorneyId: this.attorneyDetails._id
            })
            .then((response) => {
                this.loading = false
                this.allowedDateList = response.data.data
                console.log('date we allow')
                console.log(this.allowedDateList)
                this.allowedLoader = true
                this.getAvailableTimes()
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },
    },

    methods: {
        backDialog() {
            this.e1 = 1
            this.checkbox = false
        },

        async getAvailableTimes() {
            let formattedDate = moment(this.availableDate, "YYYY-MM-DD").format("MM-DD-YYYY")
            this.loading = true;
            await this.$store.dispatch('getAvailableTimes', {
                date: formattedDate,
                attorneyId: this.attorneyDetails._id
            })
            .then((response) => {
                this.loading = false
                this.availableTimes = response.data.data
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        allowedDates(val) {
            const dDates = this.allowedDateList.map(date => moment(date, "MM-DD-YYYY").format("YYYY-MM-DD"));
            return dDates.includes(val) ? false : true
        },

        bookAppointment() {
            this.bookAppointmentloader = true;
            this.$store.dispatch('bookAppointment', {
                date: new Date(this.availableDate),
                from: moment(this.selectedTime.from, ["h:mm A"]).format("HH:mm"),
                to: moment(this.selectedTime.to, ["h:mm A"]).format("HH:mm"),
                attorney_id: this.attorneyDetails._id,
                case_id: this.caseId,
                status: 'booked',
                is_consultant: 0
            })
            .then((response) => {
                this.bookAppointmentloader = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.bookDialog = false
                this.$store.dispatch('getClientAppointments')
            })
            .catch((error) => {
                this.bookAppointmentloader = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        selectItem(item) {
            this.selectedCaseStatus = item.status
            this.e1 = 2
            console.log(item)

            if(item.status == 'retained') {
                this.matchRetainLoader = true
                this.retainAttorneysList = []
                this.$store.dispatch('getAttorneyById', item.retained_attorney_id)
                .then((response) => {
                    this.retainAttorneysList.push({
                        attorney: response.data.attorney,
                        attorney_profile: response.data.attorney_profile,
                        case_id: item._id,
                        client_id: item.client_id
                    })
                    this.matchRetainLoader = false
                })
                .catch(() => {

                })
            }

            if(item.status == 'matched') {
                this.matchRetainLoader = true
                this.matchAttorneysList = []
                for (let i = 0; i < item.matched_attorneys_id.length; i++) {
                    this.$store.dispatch('getAttorneyById', item.matched_attorneys_id[i])
                    .then((response) => {
                        this.matchAttorneysList.push({
                            attorney: response.data.attorney,
                            attorney_profile: response.data.attorney_profile,
                            case_id: item._id,
                            client_id: item.client_id
                        })
                        this.matchRetainLoader = false
                    })
                    .catch(() => {

                    })
                }
            }



            // this.caseId = item._id
            // this.attorneyId = '632f5fdadba78c987e9cb47a'

            // this.$store.dispatch('getAttorneyAvailability', this.attorneyId)
            // .then((response) => {
            //     console.log(response)
            //     this.availability = response.data.data
            // })
            // .catch((error) => {
            //     this.snackbar = true
            //     this.message = error.response.data.error
            //     this.bgColor = 'error'
            // })

            // this.getAttorneyProfile(item)
                
        },

        selectedAttorney(item) {
            console.log(item)
            this.caseId = item.case_id
            this.attorneyDetails = item.attorney
            this.attorneyprofile = item.attorney_profile

            this.attorneySelectedLoader = true
            this.$store.dispatch('getAttorneyAvailability', this.attorneyDetails._id)
            .then((response) => {
                console.log(response)
                this.availability = response.data.data
                this.attorneySelectedLoader = false
                this.attorneySelected = true
                this.bookDialog = true
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        // getAttorneyProfile(item) {
        //     this.$store.dispatch('getAttorneyById', item.retained_attorney_id)
        //     .then((response) => {
        //         this.retainAttorneyDetail.attorney = response.data.attorney
        //         this.retainAttorneyDetail.attorney_profile = response.data.attorney_profile
        //         this.retainAttorneyDetail.case_id = item._id,
        //         this.retainAttorneyDetail.client_id = item.client_id
        //         console.log(this.retainAttorneyDetail)
        //     })
        //     .catch(() => {

        //     })
        // },

        checkDay() {
            var date = new Date(this.date)
            var dayIndex = date.getDay()
            if(dayIndex == 0) {
                this.daysAvailable = this.availability.filter( i => 'Sunday'.includes( i.day ) )
            } else if(dayIndex == 1) {
                this.daysAvailable = this.availability.filter( i => 'Monday'.includes( i.day ) )
            } else if(dayIndex == 2) {
                this.daysAvailable = this.availability.filter( i => 'Tuesday'.includes( i.day ) )
            } else if(dayIndex == 3) {
                this.daysAvailable = this.availability.filter( i => 'Wednesday'.includes( i.day ) )
            } else if(dayIndex == 4) {
                this.daysAvailable = this.availability.filter( i => 'Thursday'.includes( i.day ) )
            } else if(dayIndex == 5) {
                this.daysAvailable = this.availability.filter( i => 'Friday'.includes( i.day ) )
            } else if(dayIndex == 6) {
                this.daysAvailable = this.availability.filter( i => 'Saturday'.includes( i.day ) )
            }
        },
    },

    computed: {
        allCase() {
            var allCase = this.$store.state.clientCases.data;
            return allCase.filter(obj => obj.status !== 'open')
        },
    }

  }
</script>

<style scoped>

.title_bg {
    background-color: #023E7D;
    color: #fff;
}

.stepper_btn {
    color: #fff;
}

.some-style >>> .v-input__slot::before {
    border-style: none !important;
}

.check_container {
    border: solid 1px #023E7D;
    border-radius: 3px;
    background-color: #F6FAFF;
    padding: 5px 10px;
    height: 52px;
}

.check_text {
    color: #023E7D;
}

.form_link {
    color: #023E7D;
    font-weight: bold;
    text-decoration: none;
}

</style>