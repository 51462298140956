<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic text-capitalize">Hello! {{userData?.user?.first_name}}</h3>
                    <p class="subtopic">Let’s find you an attorney</p>
                    <v-row class="my-4">
                        <v-col cols="12" md="4" lg="4">
                            <router-link class="card_link" to="/client/appointments">
                                <v-card
                                color="#FFF7CC"
                                flat
                                >
                                    <v-card-text class="pt-10 text-end">
                                        <h2 class="card_figure">{{clientAppointments?.length}}</h2>
                                        <span class="card_figure-text text-caption">Appointments</span>
                                    </v-card-text>
                                </v-card>
                            </router-link>
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                            <router-link class="card_link" to="/client/cases">
                                <v-card
                                color="#B7EBF1"
                                flat
                                >
                                    <v-card-text class="pt-10 text-end">
                                        <h2 class="card_figure">{{clientCases.matchedCount}}</h2>
                                        <span class="card_figure-text text-caption">Matched Attorneys</span>
                                    </v-card-text>
                                </v-card>
                            </router-link>
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                            <router-link class="card_link" to="/client/cases">
                                <v-card
                                color="#FAEDD9"
                                flat
                                >
                                    <v-card-text class="pt-10 text-end">
                                        <h2 class="card_figure">{{clientCases.openCount}}</h2>
                                        <span class="card_figure-text text-caption">Prospective  Attorneys</span>
                                    </v-card-text>
                                </v-card>
                            </router-link>
                        </v-col>
                    </v-row>
                    <h4 class="topic mb-2">Attorneys</h4>
                    <div class="d-flex justify-space-between align-center">
                        <v-text-field
                            v-model="search"
                            rounded
                            filled
                            dense
                            label="Search attorney's name or location"
                            append-icon="mdi-magnify"
                            :hide-details="true"
                            class="some-style"
                        ></v-text-field>
                        <div class="ms-4">
                            <v-chip
                                class="pe-0 me-2"
                                color="#DCE8F8"
                            >
                                {{ attorneyType[selectedType] }}
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    small
                                    class="ms-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon small>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item-group
                                        v-model="selectedType"
                                        color="primary"
                                    >
                                    <v-list-item
                                    v-for="(item, i) in attorneyType"
                                    :key="i"
                                    >
                                    <v-list-item-title>{{ item }}</v-list-item-title>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                </v-menu>
                            </v-chip>
                            <v-chip
                                class="pe-0 me-2"
                                color="#DCE8F8"
                            >
                                {{ practiceAreas[selectedArea].name }}
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    small
                                    class="ms-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon small>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                    <v-responsive
                                    class="overflow-y-auto"
                                    max-height="300"
                                    >

                                        <v-responsive>
                                            <v-list>
                                                <v-list-item-group
                                                    v-model="selectedArea"
                                                    color="primary"
                                                >
                                                <v-list-item
                                                v-for="(item, i) in practiceAreas"
                                                :key="i"
                                                >
                                                <v-list-item-title>{{ item.name }}</v-list-item-title>
                                                </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-responsive>
                                    </v-responsive>
                                </v-menu>
                            </v-chip>
                            <v-chip
                                class="ps-2 pe-0 me-2"
                                color="#F5F5F5"
                            >
                            <span class="slot_rating-text">{{ selectedRate === 0 ? '-' : ratings[selectedRate] }}</span>
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon size="16">
                                        mdi-star-outline
                                    </v-icon>
                                    </v-btn>
                                </template>
                                    <v-responsive
                                    class="overflow-y-auto"
                                    max-height="300"
                                    >
                                        <v-responsive>
                                            <v-list>
                                                <v-list-item-group
                                                    v-model="selectedRate"
                                                    color="primary"
                                                >
                                                <v-list-item
                                                v-for="(item, i) in ratings"
                                                :key="i"
                                                >
                                                <v-list-item-title>
                                                    <span class="slot_rating-text">{{ item === 0 ? 'All Ratings' : item }}</span>
                                                    <v-icon size="16">
                                                        mdi-star-outline
                                                    </v-icon>
                                                </v-list-item-title>
                                                </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-responsive>
                                    </v-responsive>
                                </v-menu>
                            </v-chip>
                            <v-chip
                                class="px-1"
                                color="#F5F5F5"
                            >
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon v-if="selectedStatus != 0" size="16">
                                        {{ selectedStatus === 1 ? 'mdi-check-circle-outline' : 'mdi-checkbox-blank-circle-outline' }}
                                    </v-icon>
                                    <v-icon v-else size="16">
                                        mdi-checkbox-multiple-marked-circle-outline
                                    </v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item-group
                                        v-model="selectedStatus"
                                        color="primary"
                                    >
                                    <v-list-item
                                    v-for="(item, i) in verifications"
                                    :key="i"
                                    >
                                    <v-list-item-title>{{ item }}</v-list-item-title>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                </v-menu>
                            </v-chip>
                        </div>
                    </div>
                    <v-row class="my-1">
                        <v-col cols="12">
                            <v-card flat class="d-flex flex-nowrap" style="overflow-x: auto;">
                                    <div
                                    v-if="attorneysLoader === false"
                                    class="d-flex justify-start"
                                    >
                                    <v-card
                                        color="#023E7D"
                                        v-for="(item, i) in boostedAttorneyAds"
                                        :key="i"
                                        class="me-4 col-auto"
                                    >
                                        <v-card-text class="d-flex justify-start">
                                            <v-avatar size="40">
                                                <img
                                                    :src="item.profile_image_url"
                                                    alt="profile image"
                                                >
                                            </v-avatar>
                                            <div class="ms-2">
                                                <h3 class="card_title">{{item.user_id.last_name}} {{item.user_id.first_name}} <v-icon v-show="item.is_profile_verified" color="#e9c46a" small>mdi-check-decagram</v-icon></h3>
                                                <div class="d-flex justify-start align-center">
                                                    <span class="card_text">{{ item.attorney_type[0] }}</span>
                                                    <span class="ms-1 card_text" v-show="item?.attorney_type?.length > 1">& More</span>
                                                    <div class="mx-2 line2"></div>
                                                    <span class="rating_text2">{{ item.avg_rating || 0 }}</span>
                                                    <v-icon small class="profile_icon" color="#FFF">
                                                        mdi-star-outline
                                                    </v-icon>
                                                </div>
                                            </div>
                                        </v-card-text>
                                        <div class="px-4 pb-2 d-flex justify-space-between align-center">
                                            <v-chip
                                            v-show="item.has_active_boost"
                                            small
                                            outlined
                                            color="#fff"
                                            class="promo_tag"
                                            >
                                            Boosted
                                            </v-chip>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                            icon
                                            small
                                            outlined
                                            color="#fff"
                                            @click="attProfile(item)"
                                            >
                                            <v-icon small>mdi-arrow-top-right</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-card>
                                    </div>
                                    <div v-else class="d-flex justify-start">
                                        <v-skeleton-loader
                                        v-for="i in 4"
                                        :key="i"
                                        width="200"
                                        height="120"
                                        type="card"
                                        class="me-4"
                                        ></v-skeleton-loader>
                                    </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12">
                            <v-responsive
                            class="overflow-y-auto"
                            max-height="400"
                            >
                                <v-responsive>
                                <div
                                v-if="attorneysLoader === false"
                                >
                                <v-row>
                                    <v-col 
                                    lg="4"
                                    v-for="(item, i) in regularAttorneyAds"
                                    :key="i"
                                    >
                                        <v-card
                                            outlined
                                        >
                                            <v-card-title>
                                                <v-avatar size="40">
                                                    <img
                                                        :src="item.profile_image_url"
                                                        alt="profile image"
                                                    >
                                                </v-avatar>
                                                <v-spacer></v-spacer>
                                            </v-card-title>
                                            <v-card-text>
                                                <h3>{{item.user_id.last_name}} {{item.user_id.first_name}} <v-icon v-show="item.is_profile_verified" color="#023E7D" small>mdi-check-decagram</v-icon></h3>
                                                
                                                <div class="d-flex justify-start align-center">
                                                    <span>{{ item.attorney_type[0] }}</span>
                                                    <span v-show="item?.attorney_type?.length > 1">& More</span>
                                                    <div class="mx-2 line"></div>
                                                    <span class="rating_text">{{ item.avg_rating || 0}}</span>
                                                    <v-icon small class="profile_icon" color="#7E889A">
                                                        mdi-star-outline
                                                    </v-icon> 
                                                </div>
                                                <div class="pt-2 d-flex justify-space-between align-center">
                                                    <span class="action_text">
                                                        {{item.user_id.state}}
                                                    </span>
                                                    <v-btn
                                                    icon
                                                    small
                                                    outlined
                                                    @click="attProfile(item)"
                                                    >
                                                    <v-icon small>mdi-arrow-top-right</v-icon>
                                                    </v-btn>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                </div>
                                <v-row v-else>
                                    <v-col 
                                    v-for="i in 3"
                                    :key="i"
                                    lg="4"
                                    >
                                        <v-skeleton-loader
                                        type="card"
                                        ></v-skeleton-loader>
                                    </v-col>
                                </v-row>
                                </v-responsive>
                            </v-responsive>
                        </v-col>
                    </v-row>
                    <div class="py-2 d-flex justify-end">
                        <v-pagination
                        v-model="page"
                        :length="attorneys?.pagination?.totalPages"
                        circle
                        color="#023E7D"
                        ></v-pagination>
                    </div>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="right_sidebar">
                    <ApptCard />
                    <ProgCard />
                    <CaseCard />
                </v-col>
                
            </v-row>
        </v-container>

        <v-dialog
        v-model="attProDialog"
        max-width="550"
        >
            <v-card flat>
                <v-img
                    :aspect-ratio="16/8"
                    :src="attorneyProfile.profile_image_url"
                >
                </v-img>

                <v-card-text v-show="attorneyProfile != {}">
                    <div class="d-flex justify-start">
                        <h4 class="mt-6 mb-2 me-2 profile_title">{{attorneyProfile?.user_id?.first_name}} {{attorneyProfile?.user_id?.last_name}}</h4>
                        <v-icon
                        v-show="attorneyProfile?.is_profile_verified"
                        color="#023E7D"
                        >
                        mdi-check-decagram
                        </v-icon>
                    </div>
                    <span 
                    class="profile_subtitle text-capitalize"
                    >
                    {{ attorneyProfile?.attorney_type | commaSeparated }}
                    </span>
                    
                    <v-responsive
                    class="mt-4 overflow-y-auto"
                    max-height="230"
                    >
                        <v-responsive>
                    <v-row>
                        <v-col cols="6" lg="6">
                            <h5>Language(s)</h5>
                            <div class="profile_text">
                                <span 
                                class="text-capitalize"
                                >
                                {{ attorneyProfile?.user_id?.spoken_language | commaSeparated }}, 
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="6" lg="6">
                            <h5>State Licensed</h5>
                            <div class="profile_text">
                                <span 
                                v-for="(item, i) in attorneyProfile?.licensed_areas"
                                :key="i"
                                >
                                {{item.state}}, 
                                </span>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <h5>Practice Area</h5>
                            <div class="profile_text">
                                <span>
                                {{attorneyProfile?.practice_area | commaSeparated}}
                                </span>
                            </div>
                        </v-col>
                        <v-col lg="12">
                            <h5>About</h5>
                            <div 
                            v-if="'contact_info' in attorneyProfile"
                            class="profile_text"
                            >
                                {{ attorneyProfile?.contact_info.about }}
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-divider></v-divider>
                            <div v-if="attorneyProfile?.ratings?.length == 0">
                                <h5 class="my-2">No reviews added</h5>
                            </div>
                            <div v-else>
                                <h4 class="my-2">Review summary</h4>
                                <div class="mb-4 d-flex justify-start align-center">
                                    <h3 class="rate_title">{{ attorneyProfile?.avg_rating | formatNumber }}</h3>
                                    <div class="ms-2">
                                    <v-rating
                                    :value="attorneyProfile?.avg_rating"
                                    background-color="orange lighten-3"
                                    class="me-1"
                                    color="orange"
                                    half-increments
                                    readonly
                                    dense
                                    small
                                    ></v-rating>
                                    Reviews ({{ attorneyProfile?.ratings?.length }})
                                    </div>
                                </div>
                                <div
                                v-for="(item, index) in attorneyProfile?.ratings"
                                :key="index"
                                class="mb-2"
                                >
                                    <v-list-item
                                    dense
                                    class="px-0"
                                    >
                                    <v-list-item-avatar rounded size="35" color="#DCE8F8">
                                        <v-icon size="25" color="#85A4C7" dark>
                                            mdi-account
                                        </v-icon>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.full_name }}</v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-rating
                                            :value="item.rating"
                                            background-color="orange lighten-3"
                                            class="me-1"
                                            color="orange"
                                            size="15"
                                            half-increments
                                            readonly
                                            dense
                                            ></v-rating>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                    <div class="review_text">
                                        <div :class="{ 'text-truncate': !item.expanded }">
                                        {{ item.review }}
                                        </div>
                                        <a
                                        v-if="item.showToggle && item.review.length > 50"
                                        @click="toggleReview(i, index)"
                                        >
                                        {{ item.expanded ? 'Show Less' : 'See More' }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                    </v-responsive>
                </v-responsive>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <CreateConsult :attorneyItems="attorneyProfile" />
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- open case -->
        <v-dialog
        v-model="openCaseDialog"
        max-width="420"
        >
        <v-card class="py-4" color="#023E7D">
            <v-card-title class="mb-4">
                <v-avatar size="40" color="#DCE8F8">
                    <v-icon color="#023E7D" dark>
                        mdi-briefcase-outline
                    </v-icon>
                </v-avatar>
            </v-card-title>
            <v-card-text>
                <h3 class="dialog_title">Welcome! open a case.</h3>
                <p class="dialog_subtitle">Sed egestas, dui a accumsan sagittis, sapien mauris placerat  eu bi purus eros felis viverra nisi nec tincidunt ull amcorper. Donec ac blandit felis.</p>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="white"
                text
                @click="openCaseDialog = false"
            >
                Later
            </v-btn>
            <v-btn
                color="white"
                dark
                depressed
                route to="/client/cases"
                class="dialog_btn"
            >
                Open Now
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

    </div>
</template>

<script>
import ApptCard from '../../components/client/AppointmentCardClient.vue'
import ProgCard from '../../components/client/ProgressCardClient.vue'
import CaseCard from '../../components/client/CaseCardClient.vue'
import Sidebar from '../../components/client/SidebarClient.vue'
import Navbar from '../../components/client/NavbarClient.vue'
import CreateConsult from '../../components/client/NewConsultation.vue'

  export default {

    components: {
        ApptCard,
        ProgCard,
        CaseCard,
        Sidebar, 
        Navbar,
        CreateConsult
    },

    data () {
      return {
        attProDialog: false,
        openCaseDialog: false,
        attorneysLoader: false,
        attorneys: [],
        attorneyProfile: {},
        imgPlaceholder: 'https://res.cloudinary.com/dzsshr0iz/image/upload/v1652961985/Ilerah/avatar-ph_pce6l3.jpg',
        attorneyType: ['All Type', 'Criminal Defense', 'Civil Plaintiff', 'Civil Defense', 'Transaction'],
        verifications: ['Both', 'Verified', 'Unverified'],
        ratings: [0, 1, 2, 3, 4, 5],
        selectedType: 0,
        selectedArea: 0,
        selectedRate: 0,
        selectedStatus: 1,
        boostedAttorneyAds: [],
        regularAttorneyAds: [],
        search: '',
        page: 1,
        limit: 10,
        type: '',
        area: '',
        rating: 0,
        vetStatus: ''
      }
    },

    created: function () {
    //   setTimeout(() => {
    //       this.openCaseDialog = true
    //   }, 5000);
      this.$store.dispatch('getPractice')
      this.allAttorneys()
      this.getAllCases()
    },

    watch: {
        selectedType(v) {
            if(v != 0) {
                this.type = this.attorneyType[v]
            } else {
                this.type = ''
            }
            this.allAttorneys()
        },
        selectedArea(v) {
            console.log(v)
            if(v != 0) {
                this.area = this.practiceAreas[v].name
            } else {
                this.area = ''
            }
            this.allAttorneys()
        },
        selectedRate(v) {
            if(v != 0) {
                this.rating = this.ratings[v]
            } else {
                this.rating = 0
            }
            this.allAttorneys()
        },
        selectedStatus(v) {
            if(v != 0) {
                this.vetStatus = this.verifications[v] == 'Verified' ? true : false
            } else {
                this.vetStatus = ''
            }
            this.allAttorneys()
        },
        page() {
            this.allAttorneys()
        },
        search(val) {
            this.allAttorneys(val);
        },
    },

    methods: {
        getAllCases() {
            this.$store.dispatch('getClientCases', {
                status: '',
                search: '',
                page: this.page,
                limit: this.limit,
                order: '',
            })
        },

        attProfile(item) {
            console.log(item)
            
            this.attorneyProfile  = item;
            if (this.attorneyProfile && this.attorneyProfile.ratings) {
                this.attorneyProfile.ratings.forEach((rating) => {
                    this.$set(rating, 'expanded', false);
                    this.$set(rating, 'showToggle', true);
                });
            }
            this.attProDialog = true

        },

        // randomize() {
        //     for (let i = this.attorneys.length - 1; i > 0; i--) {
        //         let randomIndex = Math.floor(Math.random() * i);
        //         let temp = this.attorneys[i];
        //         this.$set(this.attorneys, i, this.attorneys[randomIndex]);
        //         this.$set(this.attorneys, randomIndex, temp);
        //     }
        // },

        async allAttorneys(v) {
            this.loading = true;
            this.attorneysLoader = true
            await this.$store.dispatch('attorneysAds', {
                search: v === undefined ? '' : v,
                type: this.type,
                area:  this.area,
                rating:  this.rating === 0 ? '' : this.rating,
                vetStatus:  this.vetStatus 
            })
            .then((response) => {
                this.attorneys = response.data.data
                this.boostedAttorneyAds = this.attorneys.filter(ads => ads.has_active_boost === true);
                this.regularAttorneyAds = this.attorneys.filter(ads => ads.has_active_boost === false);
                this.attorneysLoader = false
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },
    },

    computed: {
        userData() {
            return this.$store.state.userData;
        },

        clientCases() {
            return this.$store.state.clientCases;
        },

        clientAppointments() {
            return this.$store.state.clientAppointments;
        },

        practiceAreas() {
            let newObject = {name: "All Areas", description: "All practice areas"};
            return [newObject, ...this.$store.state.practiceAreas];
        },
    }

  }
</script>

<style scoped>
.col-auto {
  flex: 0 0 auto;
}
.topic {
    color: #023E7D;
}
.subtopic {
    color: #7E889A;
}
.card_figure {
    color: #023E7D;
    font-size: 25px;
    margin-bottom: 8px;
}
.card_figure-text {
    color: #023E7D;
}
.attorney_card {
    background-color: #FBFBFB;
    border-radius: 5px;
    padding: 10px;
}
.attorney_card-box {
    height: 34px;
}
.some-style >>> .v-input__slot::before {
    border-style: none !important;
}
.dialog_title {
    font-size: 25px;
    color: #FFF;
    margin-bottom: 20px;
}
.dialog_subtitle {
    color: #FFF;
}
.dialog_btn {
    color: #023E7D;
}
.profile_title {
    font-size: 25px;
    color: #023047;
}
.profile_pill {
    color: #023E7D;
    font-weight: 600;
}
.profile_icon {
    margin-top: -4px;
}
.action_text {
    font-size: 14px;
    color: #7E889A;
}
.profile_icon-text {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #023E7D;
}
.rating_text {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
}
.rating_text2 {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
}
.slot_rating-text {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin-top: 2px;
}
.line {
    width: 1px;
    height: 25px;
    background-color: #C4C4C4;
}
.line2 {
    width: 1px;
    height: 25px;
    background-color: #C4C4C4;
}
.profile_text {
    color: #2F2E41;
}
.right_sidebar {
    border-left: 1px solid #e9ecef;
}
.promo_tag {
    padding-left: 8px;
    padding-right: 7px;
    padding-top: 2px;
}
.card_title, .card_text {
    color: #fff;
}
.rates_icon {
    color: #fff;
}
.rate_title {
    font-size: 40px;
}
.review_text {
  font-size: 13px;
}
.card_link {
    text-decoration: none;
}
</style>