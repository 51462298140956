<template>
    <div>
        <v-btn
        small
        color="#023E7D"
        text
        @click="setCalenDialog = true"
        >
        <v-icon
            left
            small
        >
        mdi-plus-circle-outline
        </v-icon>
            Edit Calendar
        </v-btn>
        
        <v-dialog
        v-model="setCalenDialog"
        scrollable
        max-width="900"
        >
        <v-card>
            <v-card-title class="card_title">
            Consultation Setup
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-4" style="max-height: 500px;">
                <v-row class="mt-2">
                    <v-col cols="4">
                        <p>Set Appointment Duration</p>
                        <v-select
                        v-model="duration"
                        :items="durations"
                        filled
                        dense
                        label="Duration"
                        class="some-style"
                        >
                        <template v-slot:selection="{ item }">
                            {{ item }} minutes
                        </template>
                        <template v-slot:item="{ item }">
                            {{ item }} minutes
                        </template>
                        </v-select>
                    </v-col>
                    <v-col cols="8">
                        <p>Set Schedule</p>
                        <v-btn
                        color="#F3F3F3"
                        elevation="0"
                        x-large
                        class="schedule_btn"
                        :disabled="!duration"
                        @click="selectDay"
                        >
                            {{ scheduleStatus ? 'Add Schedule' : 'Edit Schedule' }}
                        <v-icon
                            right
                        >
                            {{ scheduleStatus ? 'mdi-plus' : 'mdi-pencil-outline' }}
                        </v-icon>
                        </v-btn>
                        <v-progress-circular
                        v-show="scheduleLoader"
                        indeterminate
                        color="#023E7D"
                        :size="22"
                        class="ms-4"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3" 
                    v-for="(day, dayIndex) in schedules" :key="dayIndex"
                    >
                        <div>
                            <h4 class="mb-2">{{ day.day }}</h4>
                            <div>
                                <v-chip
                                v-for="(timeItem, timeIndex) in day.time"
                                :key="timeIndex"
                                class="me-2 mb-2"
                                close
                                small
                                color="#caf0f8"
                                text-color="#023E7D"
                                @click:close="removeScheduleTime(dayIndex, timeIndex)"
                                >
                                <b>{{timeItem.from | resetTime}} - {{timeItem.to | resetTime}}</b>
                                </v-chip>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                <div class="mt-6">
                    <div class="d-flex justify-space-between align-center">
                        <div>
                            <h3 class="mb-2">Block Date</h3>
                            <span>Prevent unwanted appointments by selecting and blocking dates on your calendar either by selecting dates, selecting a range or both. Keep your availability in check and maintain control over your schedule.</span>
                        </div>
                        <v-btn 
                        icon
                        :disabled="!hasTimeObjects"
                        @click="openBlockedDialog"
                        >
                            <v-icon color="#023E7D">mdi-plus-circle-outline</v-icon>
                        </v-btn>
                    </div>
                    
                    <v-row class="mt-4">
                        <v-col 
                        cols="3"
                        v-for="(item, i) in dates"
                        :key="i"
                        >
                            <v-card outlined>
                                <v-list dense>
                                    <v-list-item>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ item | formatDate }}</v-list-item-title>
                                        <v-list-item-subtitle>All Day - Blocked</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                        <v-menu 
                                        offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn 
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                @click="deleteDate(dates, item)"
                                                >
                                                <v-list-item-title>Delete</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                        
                    </v-row>
                    <v-row>
                        <v-col 
                        cols="6"
                        v-for="(item, i) in dateRange"
                        :key="i"
                        >
                            <v-card outlined>
                                <v-list dense>
                                    <v-list-item>
                                        <v-list-item-content>
                                        <v-list-item-title>{{ item.start_date | formatDate }} <v-icon small>mdi-electric-switch-closed</v-icon> {{ item.end_date | formatDate }}</v-list-item-title>
                                        <v-list-item-subtitle>All Day - Blocked</v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                        <v-menu 
                                        offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn 
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                @click="deleteDateRange(dateRange, item)"
                                                >
                                                <v-list-item-title>Delete</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                        cols="6"
                        v-for="(item, i) in dateTime"
                        :key="i"
                        >
                            <v-card outlined>
                                <v-list dense>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <div class="mb-4 d-flex justify-start">
                                                <div
                                                v-for="(date, dateIndex) in item.dates"
                                                :key="dateIndex"
                                                >
                                                    <span>{{ date | formatDate }}</span>
                                                    <v-icon size="6" class="mx-1">mdi-circle</v-icon>
                                                </div>
                                            </div>
                                            <div>
                                                <v-chip
                                                v-for="(time, timeIndex) in item.period"
                                                :key="timeIndex"
                                                class="me-1 mb-1"
                                                small
                                                color="#EEEEEE"
                                                text-color="#023E7D"
                                                >
                                                {{time.from | resetTime}} - {{time.to | resetTime}}
                                                </v-chip>
                                            </div>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                        <v-menu 
                                        offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn 
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                @click="deleteDateTime(dateTime, item)"
                                                >
                                                <v-list-item-title>Delete</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                        cols="6"
                        v-for="(item, i) in dateTimeRange"
                        :key="i"
                        >
                            <v-card outlined>
                                <v-list dense>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title class="mb-4">{{ item.date_range[0].start_date | formatDate }} <v-icon small>mdi-electric-switch-closed</v-icon> {{ item.date_range[0].end_date | formatDate }}</v-list-item-title>
                                            <div>
                                                <v-chip
                                                v-for="(time, timeIndex) in item.period"
                                                :key="timeIndex"
                                                class="me-1 mb-1"
                                                small
                                                color="#EEEEEE"
                                                text-color="#023E7D"
                                                >
                                                {{time.from | resetTime}} - {{time.to | resetTime}}
                                                </v-chip>
                                            </div>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                        <v-menu 
                                        offset-y
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn 
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                >
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item
                                                @click="deleteDateTimeRange(dateTimeRange, item)"
                                                >
                                                <v-list-item-title>Delete</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                        </v-list-item-action>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <v-dialog
        v-model="addTimeDialog"
        max-width="400"
        persistent
        >
        <v-card>
            <v-card-title>
            Add Schedule
            </v-card-title>
            <v-card-text class="mt-4">
                <v-form
                ref="form"
                >
                <v-row>
                    <v-col lg="6">
                        <v-text-field
                        v-model="scheduleFrom"
                        label="From"
                        :hide-details="true"
                        outlined
                        dense
                        @click="openTimePicker('scheduleFrom')"
                        readonly
                        ></v-text-field>
                    </v-col>
                    <v-col lg="6">
                        <v-text-field
                        v-model="scheduleTo"
                        label="To"
                        :hide-details="true"
                        outlined
                        dense
                        @click="openTimePicker('scheduleTo')"
                        readonly
                        ></v-text-field>
                    </v-col>
                </v-row>
                <div class="d-flex justify-space-between align-center">
                <span>Set time on week days</span>
                    <v-switch
                    v-model="switch1"
                    dense
                    inset
                    ></v-switch>
                </div>
                <div>
                    <v-chip
                    v-for="(item, i) in weekdays"
                    :key="i"
                    class="mt-2 me-2"
                    :color="item.active ? 'blue darken-1' : 'grey'"
                    :close-icon=" item.active ? 'mdi-close' : 'mdi-check'"
                    dark
                    close
                    @click="updateDayStatus(i)"
                    >
                    {{ item.day }}
                    </v-chip>
                </div>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="#023E7D"
                    text
                    :loading="setCalendarLoader"
                    @click="setCalendar"
                >
                    Done
                </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="#023E7D"
                text
                :disabled="!addTimeStatus"
                @click="setTime"
            >
            {{scheduleStatus ? 'Add' : 'Add More'}}
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- <v-dialog
        v-model="timerDialog"
        max-width="300"
        >
        <v-card>
            <v-card-title>
                Select Time
            </v-card-title>
            <v-card-text class="d-flex justify-start align-center">
                    <div>
                        <v-text-field
                        v-model="timeHour"
                        label="Hour"
                        placeholder="00"
                        :hide-details="true"
                        filled
                        class="some-style"
                        @change="fromHrsChanged"
                        ></v-text-field>
                    </div>
                    <div class="mx-1 time_demacation">:</div>
                    <div>
                        <v-text-field
                        v-model="timeMinute"
                        label="Minute"
                        placeholder="00"
                        :hide-details="true"
                        filled
                        class="some-style"
                        @change="fromHrsChanged"
                        ></v-text-field>
                    </div>
                    <div class="ms-2 pm_am-box">
                        <v-btn
                        color="#F3F3F3"
                        elevation="0"
                        small
                        
                        >
                            AM
                        </v-btn>
                        <br />
                        <v-btn
                        color="#F3F3F3"
                        elevation="0"
                        small
                        
                        >
                            PM
                        </v-btn>
                    </div>
            </v-card-text>
            <v-card-actions class="me-2">
                <v-spacer></v-spacer>
                <v-btn
                    color="#023E7D"
                    small
                    text
                    @click="timerDialog = false"
                >
                    close
                </v-btn>
                <v-btn
                    color="#023E7D"
                    small
                    text
                >
                ok
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog> -->

        <v-dialog
        v-model="dateTimeDialog"
        max-width="500"
        >
            <v-card>
                <v-card-title>
                    Add Block Date & Time
                </v-card-title>
                <v-card-text>
                    <v-card 
                    v-if="allowedDatesLoader"
                    class="d-flex justify-center align-center"
                    flat height="300"
                    >
                        <v-progress-circular
                        :size="30"
                        color="primary"
                        indeterminate
                        ></v-progress-circular>
                    </v-card>
                    <v-date-picker
                        v-else
                        color="#023E7D"
                        v-model="blockDates"
                        :events="randomDateEvent"
                        event-color="red lighten-1"
                        full-width
                        :allowed-dates="allowedDates"
                        :to-allowed-dates="(val) => toAllowedDates(val, index)"
                        :picker-date.sync="pickerDate"
                        :multiple="!calendarSwitch"
                        :range="calendarSwitch"
                        :min="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)"
                    ></v-date-picker>
                    <div class="d-flex justify-space-between align-center">
                        <span>Switch calendar to range</span>
                        <v-switch
                        v-model="calendarSwitch"
                        dense
                        inset
                        color="#023E7D"
                        :disabled="allowedDateList != [] ? true : false"
                        ></v-switch>
                    </div>
                    <div>
                        <h4>Add time phase</h4>
                            <span>Set time(s) for the selected date(s) on the calendar</span>
                            <div class="d-flex justify-space-between align-center">
                                <span>Block all day</span>
                                <v-switch
                                v-model="allDaySwitch"
                                dense
                                inset
                                color="#023E7D"
                                ></v-switch>
                            </div>
                            <div class="pb-2">
                                <v-chip
                                v-for="(item, i) in blockTime"
                                :key="i"
                                class="me-2 mb-2"
                                close
                                color="#caf0f8"
                                text-color="#023E7D"
                                @click:close="removeBlockedTime(blockTime, item)"
                                >
                                <b>{{item.from | resetTime}} - {{item.to | resetTime}}</b>
                                </v-chip>
                            </div>
                            <v-row v-show="!allDaySwitch">
                                <v-col cols="5">
                                    <v-text-field
                                    v-model="blockFrom"
                                    label="From"
                                    :hide-details="true"
                                    outlined
                                    dense
                                    @click="openTimePicker('blockFrom')"
                                    readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5">
                                    <v-text-field
                                    v-model="blockTo"
                                    label="To"
                                    :hide-details="true"
                                    outlined
                                    dense
                                    @click="openTimePicker('blockTo')"
                                    readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn
                                class="mb-2"
                                @click="addBlockedTime"
                                icon
                                >
                                <v-icon
                                >
                                mdi-plus
                                </v-icon>
                                </v-btn>
                                </v-col>
                            </v-row>
                            <div class="d-flex justify-space-between">
                                <v-btn
                                class="mt-4"
                                color="#023E7D"
                                outlined
                                @click="dateTimeDialog = false"
                                >
                                close
                                </v-btn>
                                <v-btn
                                class="mt-4"
                                color="#023E7D"
                                elevation="0"
                                dark
                                :loading="blockDateLoader"
                                @click="blockDetails"
                                >
                                Save blocked dates
                                </v-btn>
                            </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- time picker -->
        <TimePicker
        v-model="timerDialog"
        @time-selected="updateTime"
        />

        <!-- snackbar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        top
        right
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
// import FlatPickr from 'vue-flatpickr-component';
// import 'flatpickr/dist/flatpickr.css';
import TimePicker from '../TimePicker.vue';
import moment from 'moment'

  export default {

    components: {
        // FlatPickr,
        TimePicker
    },

    created: function () {
        this.getAvailability()
        this.getUnavailability()
        // console.log(this.availableTime)
    },

    watch: {
        switch1(newValue) {
            if (newValue == true) {
                for (let i = 0; i < 5; i++) {
                this.weekdays[i].active = true; // Update only indices 0 to 4
                }
            }
        },

        calendarSwitch() {
            if(this.blockDates != []) {
                this.blockDates = []
            }
        },

        pickerDate(val) {
            this.allowedDatesLoader = true
            this.getCalendarData(val)
        },
    },


    data () {
      return {
        date: null,
        setCalenDialog: false,
        checkbox: false,
        loading: false,
        snackbar: false,
        addTimeDialog: false,
        e1: 1,
        message: '',
        bgColor: '',
        dates: [],
        dateRange: [],
        dateTime: [],
        dateTimeRange: [],
        date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu1: false,
        date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu2: false,
        date3: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu3: false,
        date4: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu4: false,
        date5: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu5: false,
        time4: null,
        menuTime4: false,
        time5: null,
        menuTime5: false,
        selectedDay: '',
        schedules: [
            {
                day: 'Monday',
                time: []
            },
            {
                day: 'Tuesday',
                time: []
            },
            {
                day: 'Wednesday',
                time: []
            },
            {
                day: 'Thursday',
                time: []
            },
            {
                day: 'Friday',
                time: []
            },
            {
                day: 'Saturday',
                time: []
            },
            {
                day: 'Sunday',
                time: []
            }
        ],
        weekdays: [
            {day: 'Monday', active: false},
            {day: 'Tuesday', active: false},
            {day: 'Wednesday', active: false},
            {day: 'Thursday', active: false},
            {day: 'Friday', active: false},
            {day: 'Saturday', active: false},
            {day: 'Sunday', active: false}
        ],
        Random: [],
        Range: [],
        availableTime: [],
        blockTime: [],
        availableRangeTime: [],
        randomDateEvent: [],
        rangeDateEvent: [],
        randomDateTimeEvent: [],
        rangeDateTimeEvent: [],
        pickedDay: '',
        duration: '',
        durations: [30, 45, 60],
        availableTimeCount: 0,
        setCalendarLoader: false,
        switch1: false,
        calendarSwitch: false,
        allDaySwitch: true,
        dateTimeDialog: false,
        schedulesUpdated: false,

        timerDialog: false,
        selectedInput: '',
        scheduleFrom: '',
        scheduleTo: '',
        blockFrom: '',
        blockTo: '',

        blockDates: [],
        blockDateLoader: false,
        scheduleLoader: false,
        allowedDateList: [],
        pickerDate: null,
        blockedMenu: ['Edit', 'Delete'],
        allowedDatesLoader: false,
        deleteDateLoader: false
      }
    },

    methods: {
        deleteDate(dates, item) {
            // this.deleteDateLoader = true
            var index = dates.indexOf(item);
            if (index > -1) {
                dates.splice(index, 1);
            }
            this.dates = dates;
            this.deleteUnavailability()
        },

        deleteDateRange(dateRange, item) {
            this.deleteDateLoader = true
            var index = dateRange.indexOf(item);
            if (index > -1) {
                dateRange.splice(index, 1);
            }
            this.dateRange = dateRange;
            this.deleteUnavailability()
        },

        deleteDateTime(dateTime, item) {
            this.deleteDateLoader = true
            var index = dateTime.indexOf(item);
            if (index > -1) {
                dateTime.splice(index, 1);
            }
            this.dateTime = dateTime;
            this.deleteUnavailability()
        },
        
        deleteDateTimeRange(dateTimeRange, item) {
            this.deleteDateLoader = true
            var index = dateTimeRange.indexOf(item);
            if (index > -1) {
                dateTimeRange.splice(index, 1);
            }
            this.dateTimeRange = dateTimeRange;
            this.deleteUnavailability()
        },

        openBlockedDialog() {
            this.dateTimeDialog = true
        },

        allowedDates(val) {
            const dDates = this.allowedDateList.map(date => moment(date, "MM-DD-YYYY").format("YYYY-MM-DD"));
            return dDates.includes(val) ? false : true
        },

        // allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,
        
        openTimePicker(input) {
            this.selectedInput = input;
            this.timerDialog = true;
        },

        updateTime(time) {
            this[this.selectedInput] = time;
        },
        
        updateDayStatus(index) {
            this.weekdays[index].active = !this.weekdays[index].active;
        },

        addBlockedTime() {
            let convertFrom = moment(this.blockFrom, ['h:mm A']).format('HH:mm')
            let convertTo = moment(this.blockTo, ['h:mm A']).format('HH:mm')
            this.blockTime.push({ from: convertFrom, to: convertTo });
            this.blockFrom = null
            this.blockTo = null
        },

        selectDay(data) {
            this.pickedDay = data
            this.addTimeDialog = true
        },

        setTime() {
            this.schedulesUpdated = true
            let convertFrom = moment(this.scheduleFrom, ['h:mm A']).format('HH:mm')
            let convertTo = moment(this.scheduleTo, ['h:mm A']).format('HH:mm')
            this.weekdays.forEach((weekday, index) => {
                if (weekday.active) {
                    this.schedules[index].time.push({ from: convertFrom, to: convertTo });
                }
            });
            this.scheduleFrom = null
            this.scheduleTo = null
        },

        getAvailability() {
            function getDateSetup() {
                const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
                const dateSetup = daysOfWeek.map(day => ({
                    day: day,
                    time: []
                }));
                return dateSetup;
            }
            this.$store.dispatch('getAvailability')
            .then((response) => {
                this.schedules = getDateSetup();
                console.log('get availability')
                console.log(response)
                this.duration = response.data.data.consultation_duration
                let scheduleTimes = response.data.data.availabilities
                scheduleTimes.forEach(item => {
                    let previousSchedule = this.schedules.find(schedule => schedule.day === item.day);
                    if (previousSchedule) {
                        previousSchedule.time.push({ from: item.from, to: item.to });
                    }
                });
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        getUnavailability() {
            // function formatDate(dateString) {
            //     const parts = dateString.split('-');
            //     const year = parts[2];
            //     const month = parts[0].padStart(2, '0');
            //     const day = parts[1].padStart(2, '0');
            //     return `${year}-${month}-${day}`;
            // }

            this.$store.dispatch('getUnavailability')
            .then((response) => {
                console.log('get un-availability')
                console.log(response)
                // let random_dates = response?.data?.data[0]?.random_dates
                // const formatted_dates = random_dates.map(date => formatDate(date));
                // this.dates = formatted_dates
                this.dates = response?.data?.data[0]?.random_dates
                this.dateRange = response.data.data[0].date_range
                this.dateTime = response.data.data[0].random_date_time
                this.dateTimeRange = response.data.data[0].date_time_range
            })
            .catch(() => {
                this.snackbar = true
                this.message = 'Failed. Try again later'
                this.bgColor = 'error'
            })
        },

        deleteUnavailability() {
            this.deleteDateLoader = false
            this.$store.dispatch('updateUnAvailabilties', {
                random_dates: this.dates,
                date_range: this.dateRange,
                random_date_time: this.dateTime,
                date_time_range: this.dateTimeRange
            })
            .then(() => {
                this.getUnavailability()
                this.deleteDateLoader = false
            })
            .catch((error) => {
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        removeScheduleTime(dayIndex, timeIndex) {
            // Remove the time item from the schedules array
            this.schedules[dayIndex].time.splice(timeIndex, 1);
            this.schedulesUpdated = true
            this.setCalendar()
            this.scheduleLoader = true
        },

        removeBlockedTime(blockTime, item) {
            var index = blockTime.indexOf(item);
            if (index > -1) {
                blockTime.splice(index, 1);
            }
            return blockTime;
        },

        setCalendar() {
            if(this.schedulesUpdated == true) {
                let scheduleBreakdown = {};
                this.schedules.forEach(schedule => {
                const { day, time } = schedule;
                const daySchedule = time.map(slot => ({
                from: slot.from,
                to: slot.to
                }));
                scheduleBreakdown[day] = daySchedule;
                });

                this.setCalendarLoader = true
                this.$store.dispatch('setCalendar', {
                consultation_duration: this.duration,
                Monday: scheduleBreakdown.Monday,
                Tuesday: scheduleBreakdown.Tuesday,
                Wednesday: scheduleBreakdown.Wednesday,
                Thursday: scheduleBreakdown.Thursday,
                Friday: scheduleBreakdown.Friday,
                Saturday: scheduleBreakdown.Saturday,
                Sunday: scheduleBreakdown.Sunday
                })
                .then((response) => {
                    this.setCalendarLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                    this.addTimeDialog = false
                    this.scheduleLoader = false
                    this.schedulesUpdated = false
                    this.getAvailability()
                    this.updateCalendarData()
                })
                .catch((error) => {
                    this.setCalendarLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            } else {
                this.addTimeDialog = false
            }
        },

        blockDetails() {
            console.log(this.blockDates)
            let finalDate = this.blockDates.map(date => moment(date, "YYYY-MM-DD").format("MM-DD-YYYY"));
            if(this.calendarSwitch == false && this.allDaySwitch == true) {
                this.blockDateLoader = true
                this.$store.dispatch('BlockDate', {
                random_dates: finalDate,
                })
                .then((response) => {
                    this.blockDateLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                    this.dateTimeDialog = false
                    this.getUnavailability()
                    this.updateCalendarData()
                })
                .catch((error) => {
                    this.blockDateLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            } else if(this.calendarSwitch == true && this.allDaySwitch == true) {
                this.blockDateLoader = true
                this.$store.dispatch('BlockDate', {
                    date_range: [
                        {start_date: finalDate[0], end_date: finalDate[1]}
                    ]
                })
                .then((response) => {
                    this.blockDateLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                    this.dateTimeDialog = false
                    this.getUnavailability()
                    this.updateCalendarData()
                })
                .catch((error) => {
                    this.blockDateLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            } else if (this.calendarSwitch == false && this.allDaySwitch == false && this.blockTime != []) {
                this.blockDateLoader = true
                this.$store.dispatch('BlockDateTime', {
                random_date_time: {
                    dates: finalDate,
                    period: this.blockTime,
                }
                })
                .then((response) => {
                    this.blockDateLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                    this.dateTimeDialog = false
                    this.getUnavailability()
                    this.updateCalendarData()
                })
                .catch((error) => {
                    this.blockDateLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            }else if(this.calendarSwitch == true && this.allDaySwitch == false && this.blockTime != []) {
                this.blockDateLoader = true
                this.$store.dispatch('BlockDateTime', {
                date_time_range: {
                    date_range: [
                        {start_date: finalDate[0], end_date: finalDate[1]}
                    ],
                    period: this.blockTime,
                }
                })
                .then((response) => {
                    this.blockDateLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                    this.dateTimeDialog = false
                    this.getUnavailability()
                    this.updateCalendarData()
                })
                .catch((error) => {
                    this.blockDateLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            }
        },

        getCalendarData(val) {
            
            this.$store
            .dispatch("getAttorneyCalendar", {
                date: `${val}-01`,
                viewType: 'month',
            })
            .then((response) => {
                let blockedDate = response.data.data.calendars.data;
                let dateList = blockedDate.map(event => event.date);
                this.allowedDateList = dateList.map(date => moment(date).format("MM-D-YYYY"));
                this.allowedDatesLoader = false
                // this.allowedDateList
                // this.$refs.calendar.checkChange();
                // this.updateRange();
            });
        },

        updateCalendarData() {
            this.$store
            .dispatch("getAttorneyCalendar", {
                date: this.currentDate,
                viewType: 'month',
            })
            .then(() => {
            });
        },

        clearForm() {
            this.$refs.form.reset();
        }
    },

    computed: {
        scheduleStatus() {
            const allEmpty = this.schedules.every(schedule => schedule.time.length === 0);
            return allEmpty;
        },
        
        addTimeStatus() {
            return (
                this.scheduleFrom &&
                this.scheduleTo &&
                this.weekdays.some(day => day.active)
            )
        },

        currentDate () {
            return moment().format("YYYY-MM-D")
        },

        hasTimeObjects() {
            return this.schedules.some(schedule => schedule.time.length > 0);
        },
    }

  }
</script>

<style scoped>
.some-style >>> .v-input__slot::before {
    border-style: none !important;
}
.form-control4, .form-control5 {
    font-size: 20px;
    padding: 20px 10px;
    width: 100%;
    background-color: #F3F3F3;
}
.mini_title {
    font-size: 12px;
}
.time_data {
    font-size: 20px;
}
.notice_text {
    font-size: 12px;
}
.schedule_btn {
    font-size: 14px;
}
.time_demacation {
    font-size: 30px;
    font-weight: 900;
}
.pm_am-box {
    border: 1px solid #F3F3F3;
}
</style>