<template>
    <div class="text-center">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="400"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
            <v-btn 
            icon 
            small 
            v-bind="attrs"
            v-on="on"
            color="#023E7D"
            class="me-2"
            >
              <v-badge
                color="green"
                dot
                overlap
              >
                <v-icon>mdi-message-processing-outline</v-icon>
              </v-badge>
            </v-btn>
        </template>
  
        <v-card>
          <div class="pa-4">
            <h4>Chats</h4>
          </div>
            <v-responsive
            class="overflow-y-auto"
            max-height="200"
            >
                <v-responsive>
                <v-list>
                  <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                  >
                    <v-list-item
                      v-for="(item) in chatUsers"
                      :key="item._id"
                      @click="itemSelected(item)"
                    >
                      <v-list-item-avatar color="#E0E0E0">
                          <v-icon small color="#023E7D">mdi-message-processing-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ userData.role != 'attorney' ? item.attorney_full_name : item.client_full_name }}</v-list-item-title>
                        <v-list-item-subtitle>{{item.email}}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action class="caption_text">
                        {{item.createdAt | formatDate}}
                    </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
            </v-responsive>
          </v-responsive>

          <!-- <v-card-actions>
            <v-spacer></v-spacer>
  
            <v-btn
              text
              small
              @click="menu = false"
            >
                Refresh
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-menu>

      <v-bottom-sheet
        v-model="sheet"
        hide-overlay
        persistent
        height="350"
        width="50%"
        inset
      >
        <v-sheet
          height="300px"
          class="chat_sheet"
        >
          <v-list-item dense>
            <v-list-item-avatar size="35" color="#E0E0E0">
                <v-icon color="#023E7D">mdi-account-outline</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ userData.role != 'attorney' ? selectedUser.attorney_full_name : selectedUser.client_full_name }}</v-list-item-title>
              <v-list-item-subtitle>Available</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                icon
                color="#023E7D"
                @click="sheet = !sheet"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <div class="px-4 my-2">
            <div class="overflow-y-auto max-height-170" ref="messageContainer">
              <div class="message_container">
                <div class="chat_filler">
                  <div class="text-center">
                    <v-icon
                      small
                      color="grey"
                      class="chat_filler-icon"
                    >
                      mdi-clock-outline
                    </v-icon>
                    <p class="chat_filler-text">
                      Messages will be deleted at after 24 hours
                    </p>
                  </div>
                  
                </div>
                <div v-for="(item, index) in chatMessages" :key="index" :class="{ 'sender': item.sender_id === userData._id, 'recipient': item.sender_id !== userData._id }">
                  <div>{{ item.message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-start align-center">
            <v-textarea
            v-model="newMessage"
            filled
            placeholder="Write a message..."
            auto-grow
            height="40"
            hide-details="auto"
            class="some-style"
            ></v-textarea>
            <div>
              <v-btn
                icon
                large
                color="#023E7D"
                class="mx-2"
                @click="sendMessage"
              >
                <v-icon>mdi-send-variant-outline</v-icon>
              </v-btn>
            </div>
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
</template>

<script>
// import CryptoJS from 'crypto-js';
import eventBus from '../EventBus';

export default {
  async created() {
    eventBus.$on("chatMessage", (data) => {
      this.updateChat(data);
    });
    
    eventBus.$on("clientIdMessage", (data) => {
      this.updateClientId(data);
    });

    await this.getChatUsers();
  },

  data() {
    return {
      menu: false,
      sheet: false,
      selectedItem: null,
      users: [
        { name: 'John Leider', text: 'Alan, how are you doing', date: 'May 20 2024' },
      ],
      chats: [],
      chatMessages: [],
      chatUsers: [],
      chatUsersCount: null,
      decryptedData: '',
      newMessage: '',
      selectedUser: {},
      sodiumMessage: '',
      sodiumNonce: '',
      // decryptedMessage: '',
      encryptionKey: 'B7lVY3x/UjkQTdxlS5T9o/vGb8c6IW5q62NbdXYRyFA=' // Ensure this is securely managed
    };
  },

  methods: {
    async getChatUsers() {
      this.loading = true;
      this.$store.dispatch('getChatUsers')
        .then((response) => {
          this.loading = false;
          this.sodiumMessage = response.data.data;
          this.sodiumNonce = response.data.nonce;

          // Decrypt the message
          this.decryptedData = this.decryptMessage(this.sodiumMessage, this.sodiumNonce,this.encryptionKey )
          .then(decryptedMessage => {
            console.log("Decrypted message:", );
            this.chatUsers = JSON.parse(decryptedMessage);
          });

          const message = "Hello, World!";

          this.encryptMessage(message, this.encryptionKey).then(encrypted => {
            console.log("Encrypted message:", encrypted.ciphertext);
            console.log("Nonce:", encrypted.nonce);
          });
        })
        .catch((error) => {
          this.loading = false;
          this.message = error.response.data.error;
        });
      },

    async decryptMessage(encryptedMessageBase64, nonceBase64, keyBase64) {
      // Decode base64 inputs
      const key = Uint8Array.from(atob(keyBase64), c => c.charCodeAt(0));
      const nonce = Uint8Array.from(atob(nonceBase64), c => c.charCodeAt(0));
      const encryptedMessage = Uint8Array.from(atob(encryptedMessageBase64), c => c.charCodeAt(0));

      // Import the key
      const cryptoKey = await crypto.subtle.importKey(
        'raw',
        key,
        'AES-GCM',
        true,
        ['decrypt']
      );

      try {
        // Decrypt the message
        const decrypted = await crypto.subtle.decrypt(
          {
            name: 'AES-GCM',
            iv: nonce
          },
          cryptoKey,
          encryptedMessage
        );

        // Convert decrypted data to a string
        const decoder = new TextDecoder();
        return decoder.decode(decrypted);
      } catch (error) {
        console.error('Decryption failed:', error);
        return null;
      }
    },
    async encryptMessage(message, keyBase64) {
      // Decode the base64 key
      const key = Uint8Array.from(atob(keyBase64), c => c.charCodeAt(0));

      // Generate a random 12-byte nonce (IV)
      const nonce = crypto.getRandomValues(new Uint8Array(12));

      // Import the key
      const cryptoKey = await crypto.subtle.importKey(
        'raw',
        key,
        'AES-GCM',
        true,
        ['encrypt']
      );

      try {
        // Encrypt the message
        const encoder = new TextEncoder();
        const encodedMessage = encoder.encode(message);

        const encrypted = await crypto.subtle.encrypt(
          {
            name: 'AES-GCM',
            iv: nonce
          },
          cryptoKey,
          encodedMessage
        );

        // Convert encrypted data to base64
        const ciphertext = btoa(String.fromCharCode(...new Uint8Array(encrypted)));
        const nonceBase64 = btoa(String.fromCharCode(...nonce));

        return {
          ciphertext,
          nonce: nonceBase64
        };
      } catch (error) {
        console.error('Encryption failed:', error);
        return null;
      }
    },
   

    updateChat(data) {
      console.log("updateChat", data);
      if (this.userData._id != data.user_id) {
        this.chatMessages.push({
          type: data.type,
          created_at: data.date,
          is_read: data.is_read,
          message: data.data.message,
          _id: data.chat_id,
          recipient_id: '',
          sender_id: this.userData._id,
          updated_at: data.date
        });
      } else {
        this.chatMessages.push({
          type: data.type,
          created_at: data.date,
          is_read: data.is_read,
          message: data.data.message,
          _id: data.chat_id,
          recipient_id: '',
          sender_id: 'recipient001',
          updated_at: data.date
        });
      }
      this.scrollToBottom();
    },

    updateClientId(data) {
      console.log(data);
    },

    sendMessage() {
      this.$store.dispatch('sendMessage', {
        chatId: this.selectedUser._id,
        recipientId: this.userData.role === 'attorney' ? this.selectedUser.client_id : this.selectedUser.attorney_id,
        message: this.newMessage
      })
      .then((response) => {
        this.loading = false;
        console.log(response);
        this.newMessage = '';
      })
      .catch((error) => {
        this.notifybar = true;
        this.message = error.response.data.error;
      });
    },

    itemSelected(v) {
      console.log(v);
      this.selectedUser = v;
      this.chatMessages = v.conversations;
      this.sheet = true;
      this.scrollToBottom();
    },

    scrollToBottom() {
      setTimeout(() => {
        const container = this.$refs.messageContainer;
        container.scrollTop = container.scrollHeight - container.clientHeight;
      }, 1000);
    }
  },

  computed: {
    userData() {
      return this.$store.state.userData.user;
    },

    bothCases() {
      return this.$store.state.bothCases;
    }
  }
};
</script>

<style scoped>
.caption_text {
    font-size: 11px;
}
/* .chat_sheet {
  margin-bottom: 10px;
} */
.chat_filler {
  height: 180px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat_filler-text {
  font-size: 13px;
  color: grey;
}
.chat_filler-icon {
  margin-top: -4px;
}
.message_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.message {
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
}
.overflow-y-auto {
  overflow-y: auto;
}
.max-height-170 {
  max-height: 170px;
}
.sender {
  background-color: #DCF8C6;
  align-self: flex-end;
  border-radius: 20px;
  padding: 5px 12px;
  margin: 4px 0;
}
.recipient {
  background-color: #E0E0E0;
  align-self: flex-start;
  border-radius: 20px;
  padding: 5px 12px;
  margin: 4px 0;
}
.some-style >>> .v-input__slot::before {
    border-style: none !important;
}
</style>