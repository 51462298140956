<template>
    <span>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
            <a
                
                class="form_link"
                @click="dialog = true"
                v-on="on"
            >
                conflict waiver.
            </a>
            </template>
            Open conflict waiver
        </v-tooltip>
      <v-dialog
        v-model="dialog"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-card-title>Conflict Waiver & Consent Agreement</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 400px;">
            <ul>
                <li>
                  <h4>1. Introduction</h4>
                  <span>Welcome to LegalLync. By using our platform, you agree to and understand the terms outlined in this Conflict Waiver and Consent Agreement. This agreement is intended to inform you of the potential risks associated with submitting your case details to multiple attorneys through our platform.</span>
                </li>
                <li>
                  <h4>2. Multiple Attorney Review</h4>
                  <span>You understand and acknowledge that by submitting your case details through LegalLync, your information may be reviewed by multiple prospective attorneys, including attorneys who may represent other parties with interests adverse to yours, or who may have existing relationships or engagements that could present a conflict of interest.</span>
                </li>
                <li>
                  <h4>3. No Attorney-Client Relationship</h4>
                  <span>You understand that submitting your case details through LegalLync does not create an attorney-client relationship between you and any attorney who views your information. An attorney-client relationship will only be established if and when you and the attorney agree to formal representation through a written engagement agreement.</span>
                </li>
                <li>
                  <h4>4. Potential Conflicts of Interest</h4>
                  <span>You acknowledge that attorneys who view your case details may be representing or may in the future represent other clients whose interests are adverse to yours. You agree that such attorneys may continue to represent these other clients without obtaining your consent or providing notice to you, even if they have reviewed your case details.</span>
                </li>
                <li>
                  <h4>5. Waiver of Conflicts</h4>
                  <span>By using LegalLync and submitting your case details for review, you expressly waive any conflict of interest that may arise from the review of your information by attorneys who may represent other clients with interests adverse to yours. You agree that you will not seek to disqualify any attorney from representing another party based on their access to or review of your case details through this platform.</span>
                </li>
                <li>
                  <h4>6. Confidentiality and Privacy</h4>
                  <span>While LegalLync takes measures to protect the confidentiality of your case details, you acknowledge that submitting sensitive or confidential information on this platform carries inherent risks. You are encouraged to limit the amount of sensitive information you provide until you have selected an attorney and established a formal attorney-client relationship.</span>
                </li>
                <li>
                  <h4>7. Disclaimer</h4>
                  <span>LegalLync does not guarantee that any attorney who reviews your case details will agree to represent you or that there will be no conflicts of interest. You are encouraged to independently verify the qualifications, experience, and conflict status of any attorney you consider for representation.</span>
                </li>
                <li>
                  <h4>8. Agreement and Consent</h4>
                  <span>By clicking "I Agree" or by otherwise using LegalLync, you acknowledge that you have read, understood, and agree to the terms of this Conflict Waiver and Consent Agreement. You further acknowledge that you have had the opportunity to seek independent legal advice regarding this agreement before consenting.</span>
                </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-dialog>
    </span>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
      }
    },
  }
</script>

<style scoped>
.form_link {
    color: #023E7D;
    font-weight: bold;
    text-decoration: none;
}
ul {
      list-style: none;
}
li {
    margin-top: 20px;
}
</style>